import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'e_id', direction: 'asc' });
  const [filter, setFilter] = useState('');
  const [hasMore, setHasMore] = useState(true); // 控制是否還有更多資料
  const observer = useRef();

  const token = localStorage.getItem('token');
  //console.log('employeesToken:', token);

  const fetchEmployees = async (page, sortKey = 'e_id', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const response = await axios.get('/api/employees', {
        params: { page, sortKey, sortDirection, filter },
        headers: { 'Authorization': `Bearer ${token}` },
      });

      // 檢查後端回傳的數據結構，並提取相應屬性
      const { data, hasMore, totalRecords } = response.data;

      //console.log('Employees data:', data);
      //console.log('Total records:', totalRecords, 'Has more:', hasMore);

      // 更新資料：如果是第一頁，覆蓋資料；如果是其他頁，合併資料
      setEmployees((prevEmployees) => (page === 1 ? data : [...prevEmployees, ...data]));

      // 更新狀態
      setHasMore(hasMore); // 確認是否還有更多資料
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // 只有當 `hasMore` 為 true 時才進行資料加載
    fetchEmployees(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, sortConfig, filter]);

  const lastEmployeeRef = useRef();
  useEffect(() => {
    if (loading || !hasMore) return; // 當正在加載或沒有更多資料時，直接返回

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        //console.log('Load more data...');
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastEmployeeRef.current) {
      observer.current.observe(lastEmployeeRef.current);
    }
  }, [loading, hasMore, employees.length]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setPage(1);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(1);
  };

  const handleDelete = (employeeId) => {
    if (window.confirm('確定要刪除這位社友嗎？')) {
      axios
        .delete(`/api/employees/${employeeId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          setEmployees((prevEmployees) => prevEmployees.filter((employee) => employee.e_id !== employeeId));
        })
        .catch((error) => {
          console.error('Error deleting employee:', error);
          alert('刪除失敗');
        });
    }
  };

  return (
    <div className="container-fluid mt-4">
      <Form.Control
        type="text"
        placeholder="搜尋社友資料..."
        value={filter}
        onChange={handleFilterChange}
        className="mb-4"
      />

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th>序號</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th>相片</th> {/* 新增相片欄位 */}
            <th onClick={() => handleSort('position_title')}>社職</th>
            <th onClick={() => handleSort('e_name')}>姓名</th>
            <th onClick={() => handleSort('e_set')}>組別</th>
            <th onClick={() => handleSort('e_set_position')}>組別職稱</th>
            <th onClick={() => handleSort('e_comp_name')}>公司</th>
            <th onClick={() => handleSort('e_comp_position')}>職稱</th>
            
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={employee.e_id} ref={index === employees.length - 1 ? lastEmployeeRef : null}>
              <td>{index + 1}</td>
              <td>{employee.store_name || '無'}</td>
              <td>
                <img
                  src={
                    employee.e_photo
                      ? `/uploads/member/${employee.e_photo}`
                      : '/uploads/member/default.jpg'
                  }
                  alt="社友相片"
                  style={{ width: '60px', height: '80px'}}
                />
              </td>
              <td>{employee.position_title || '無'}</td>
              <td>
                <a href={`tel:${employee.e_mobile}`}>{employee.e_name}</a>
              </td>
              <td>{employee.e_set || '無'}</td>
              <td>{employee.e_set_position || '無'}</td>
              <td>
                {employee.e_comp_web ? (
                  <a
                    href={employee.e_comp_web.startsWith('http') ? employee.e_comp_web : `https://${employee.e_comp_web}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {employee.e_comp_name || '查看公司網址'} {/* 超連結顯示公司名稱 */}
                  </a>
                ) : (
                  employee.e_comp_name || '無' // 沒有網址時僅顯示公司名稱
                )}
              </td>
              <td>{employee.e_comp_position || '無'}</td>
              
              {/* <td>
                <a href={`https://www.google.com/maps/search/?api=1&query=${employee.e_address}`} target="_blank" rel="noopener noreferrer">
                  {employee.e_address || '無'}
                </a>
              </td> */}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="9" className="text-center">加載中...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployeeTable;

