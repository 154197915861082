// src/NewPage.js
import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { mockData } from "./mockData";
import "bootstrap/dist/css/bootstrap.min.css";

function NewPage() {
   
    
    
      return (
        <Container fluid>
          <h2 className="my-4">Air Quality Monitoring</h2>
          <Row>
            {/* Voltage Meter */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Voltage</Card.Title>
                  <h1>{mockData.voltage} V</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Indoor Temperature */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Indoor Temperature</Card.Title>
                  <h1>{mockData.indoorTemperature} °C</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Outdoor Temperature */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Outdoor Temperature</Card.Title>
                  <h1>{mockData.outdoorTemperature} °C</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Indoor Humidity */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Indoor Humidity</Card.Title>
                  <h1>{mockData.indoorHumidity} %</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Outdoor Humidity */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Outdoor Humidity</Card.Title>
                  <h1>{mockData.outdoorHumidity} %</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* PM 2.5 */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>PM2.5</Card.Title>
                  <h1>{mockData.pm2_5} µg/m³</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* PM 10 */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>PM10</Card.Title>
                  <h1>{mockData.pm10} µg/m³</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Air Quality Index */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Air Quality Index</Card.Title>
                  <h1>{mockData.airQualityIndex} AQI</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* CO2 Level */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>CO2 Level</Card.Title>
                  <h1>{mockData.co2Level} ppm</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Flow Rate */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Flow Rate</Card.Title>
                  <h1>{mockData.flowRate} m³/min</h1>
                </Card.Body>
              </Card>
            </Col>
    
            {/* Fan State */}
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Fan State</Card.Title>
                  <h1>{mockData.fanState}</h1>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      );
   
}

export default NewPage;
