
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Form, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from './utils/permissions'; // 假設你的權限檢查函數位於 utils 目錄中
import LazyLoad from 'react-lazyload';

const AllEmployeeTable = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'e_id', direction: 'asc' });
  const [filter, setFilter] = useState('');
  const [hasMore, setHasMore] = useState(true); // 控制是否還有更多資料
  const observer = useRef(null);
  const { user } = useAuth(); // 取得使用者資訊
  const token = localStorage.getItem('token');
  //console.log('Token:', token);

  const fetchEmployees = async (page, sortKey = 'e_id', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const response = await axios.get('/api/Allemployees', {
        params: { page, sortKey, sortDirection, filter, limit: 50 },
        headers: { 'Authorization': `Bearer ${token}` },
      });

      const { data, hasMore, totalRecords } = response.data;

      //console.log('Employees data:', data);
      //console.log('Total records:', totalRecords, 'Has more:', hasMore);

      if (page === 1) {
        setEmployees(data);
      } else {
        setEmployees((prevEmployees) => [...prevEmployees, ...data]);
      }

      setHasMore(hasMore); // 確認是否還有更多資料
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPage(1); // 當 filter 或 sortConfig 改變時，重置頁碼為 1
  }, [filter, sortConfig]);

  useEffect(() => {
    fetchEmployees(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, sortConfig, filter]);

  const lastEmployeeRef = useRef();
  useEffect(() => {
    if (loading || !hasMore) return; // 當正在加載或沒有更多資料時，直接返回

    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        //console.log('Load more data...');
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastEmployeeRef.current) {
      observer.current.observe(lastEmployeeRef.current);
    }
  }, [loading, hasMore, employees.length]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setPage(1); // 當排序改變時，重置為第一頁
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setPage(1); // 當過濾條件改變時，重置為第一頁
  };

  const isValidUrl = (urlString) => {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <div className="container-fluid mt-4">
      <Form.Control
        type="text"
        placeholder="搜尋社友資料..."
        value={filter}
        onChange={handleFilterChange}
        className="mb-4"
      />

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
          <th style={{ width: '10px' }}>序號</th>
            <th style={{ width: '10px' }}>照片</th>
            <th style={{ width: '10px' }} onClick={() => handleSort('store_name')}>社別</th>
            <th style={{ width: '10px' }} onClick={() => handleSort('position_title')}>社職</th>
            <th style={{ width: '10px' }} onClick={() => handleSort('e_name')}>姓名</th>
            <th onClick={() => handleSort('e_comp_name')}>公司</th>
            <th onClick={() => handleSort('e_comp_position')}>職稱</th>
            <th onClick={() => handleSort('sql_title4')}>營業項目</th>
            {hasPermission(user, 'EDIT_ROLE') && (
              <>
                {/* <th onClick={() => handleSort('e_mobile')}>手機</th>
                <th onClick={() => handleSort('e_address')}>地址</th> */}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {employees.map((employee, index) => (
            <tr key={employee.e_id} ref={index === employees.length - 1 ? lastEmployeeRef : null}>
              <td>{index + 1}</td>
              <td>
  <LazyLoad height={80} offset={100} once>
    <img
      src={
        employee.e_photo
          ? `/uploads/member/${employee.e_photo.split('.')[0]}.webp`
          : '/uploads/member/default.webp'
      }
      alt="社友相片"
      style={{ width: '60px', height: '80px' }}
    />
  </LazyLoad>
</td>
              <td>{employee.store_name || '無'}</td>
              <td>{employee.position_title || '無'}</td>
              <td>{employee.e_name}</td>
              <td>
                {employee.e_comp_web ? (
                  <a
                    href={employee.e_comp_web.startsWith('http') ? employee.e_comp_web : `https://${employee.e_comp_web}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {employee.e_comp_name || '查看公司網址'}
                  </a>
                ) : (
                  employee.e_comp_name || '無'
                )}
              </td>
              <td>{employee.e_comp_position || '無'}</td>
              <td>{employee.sql_title4 || '無'}</td>
              {hasPermission(user, 'EDIT_ROLE') && (
                <>
                  {/* <td>
                    <a href={`tel:${employee.e_mobile}`}>{employee.e_mobile}</a>
                  </td>
                  <td>
                    <a href={`https://www.google.com/maps/search/?api=1&query=${employee.e_address2}`} target="_blank" rel="noopener noreferrer">
                      {employee.e_address2 || '無'}
                    </a>
                  </td> */}
                </>
              )}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="10" className="text-center">加載中...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default AllEmployeeTable;
