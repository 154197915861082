import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const EDMWall = () => {
  const [employees, setEmployees] = useState([]);
  const [storeNames, setStoreNames] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState('');
  const token = localStorage.getItem('token');

  const fetchStoreNames = async () => {
    try {
      const response = await axios.get('/api/stores', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setStoreNames(response.data);
    } catch (error) {
      console.error('Error fetching store names:', error);
    }
  };

  const fetchEmployees = async (currentPage, storeFilter) => {
    try {
      const response = await axios.get('/api/Allemployees', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { page: currentPage, store_name: storeFilter },
      });
      const employeesWithPhotos = (response.data.data || []).filter(employee => employee.EDM_photo);
      setEmployees(prevEmployees => currentPage === 1 ? employeesWithPhotos : [...prevEmployees, ...employeesWithPhotos]);
      setHasMore(response.data.hasMore);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    fetchStoreNames();
  }, []);

  useEffect(() => {
    fetchEmployees(page, selectedStore);
  }, [page, selectedStore]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50 && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
    setPage(1);
    setEmployees([]);
  };

  const openLightbox = (imageUrl) => {
    setLightboxImage(imageUrl);
    setIsLightboxOpen(true);
  };

  return (
    <div style={{ padding: '10px' }}>
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
        <label htmlFor="storeSelect" style={{ marginRight: '10px', fontWeight: 'bold' }}>選擇社別:</label>
        <select
          id="storeSelect"
          value={selectedStore}
          onChange={handleStoreChange}
          style={{
            width: '100%', 
            maxWidth: '300px',
            padding: '8px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            outline: 'none',
          }}
        >
          <option value="">全部社別</option>
          {storeNames.map((store) => (
            <option key={store.ss_id} value={store.ss_name}>{store.ss_name}</option>
          ))}
        </select>
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
        gap: '10px',
        textAlign: 'center'
      }}>
        {employees.map((employee, index) => (
          <div key={index} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            lineHeight: '1.2',
            margin: 0,
            padding: 0,
          }}>
            <img
              src={`/uploads/EDM/${employee.EDM_photo}?t=${new Date().getTime()}`}
              alt={employee.e_name}
              style={{
                width: '60px',
                height: '80px',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '4px',
                cursor: 'pointer',
              }}
              onClick={() => openLightbox(`/uploads/EDM/${employee.EDM_photo}?t=${new Date().getTime()}`)}
            />
            <p style={{ margin: 0, fontWeight: 'bold' }}>{employee.position_title}</p>
            <p style={{ margin: 0 }}>{employee.e_name}</p>
          </div>
        ))}
      </div>

      {/* Lightbox 顯示區域 */}
      {isLightboxOpen && (
        <Lightbox
          open={isLightboxOpen}
          close={() => setIsLightboxOpen(false)}
          slides={[{ src: lightboxImage }]}
        />
      )}
    </div>
  );
};

export default EDMWall;
