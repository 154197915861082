// services/getUserIP.js
export async function getUserIP() {
  try {
      const response = await fetch('https://ipapi.co/json/');
      const data = await response.json();
      return data;
  } catch (error) {
      console.error('無法取得使用者 IP 資訊:', error);
      return null;
  }
}
