// utils/excel.js
import * as XLSX from 'xlsx';
import axios from 'axios';

export const downloadExcel = async (data, tableName, fileName = '資料.xlsx') => {
    try {
        // 獲取表的設定
        const response = await axios.get('/api/employee-table-settings', {
            params: { table_name: tableName },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });

        const fields = response.data;
        
        // 根據可匯出的欄位過濾資料
        const exportFields = fields
            .filter(field => field.table_outexl) // 只保留可匯出的欄位
            .reduce((acc, field) => {
                acc[field.table_title_eng] = field.table_title_cht; // 建立對應的欄位標題
                return acc;
            }, {});

        const filteredData = data.map(item => {
            const filteredItem = {};
            for (const field in exportFields) {
                filteredItem[exportFields[field]] = item[field]; // 使用中文標題作為鍵
            }
            return filteredItem;
        });

        // 將資料轉換為工作表
        const worksheet = XLSX.utils.json_to_sheet(filteredData);

        // 建立一個工作簿
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, '資料');

        // 生成 Excel 檔案
        XLSX.writeFile(workbook, fileName);
    } catch (error) {
        console.error('Error downloading Excel:', error);
    }
};
