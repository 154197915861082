//JoinPage.js
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner, Card, Button, Collapse, Row, Col, Badge, Container } from "react-bootstrap";
import { FaUserFriends, FaChevronDown, FaChevronUp } from "react-icons/fa";
import * as XLSX from "xlsx";

const JoinPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activityId = queryParams.get("aid"); // 取得活動編號

  const [employees, setEmployees] = useState([]); // 存儲社友資訊
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState({}); // 控制家屬顯示的狀態
  const [stats, setStats] = useState({
    registeredCount: 0,
    leaveCount: 0,
    notRegisteredCount: 0,
    signedInCount:0,
  }); // 統計數據
  const [activityName, setActivityName] = useState(""); // Store the activity name

  useEffect(() => {
    const fetchEmployees = async () => {
      const token = localStorage.getItem("token"); // 確保動態獲取 token
      setLoading(true);
      try {
        const response = await fetch(`/api/activity-employees?aid=${activityId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // 攜帶 Token
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched Data:", data);
          // 設置社友資訊
          setActivityName(data.activityName);  // Set activity name
          setEmployees(data.employees || []);

          // 統計報名數據，包括家人
          let registeredCount = 0;
          let leaveCount = 0;
          let notRegisteredCount = 0;
          let signedInCount = 0; // 簽到人數計數

          data.employees.forEach((emp) => {
            if (emp.color === "blue") registeredCount += 1; // 社友已報名
            if (emp.color === "red") leaveCount += 1; // 社友請假
            if (emp.color === "black") notRegisteredCount += 1; // 社友未報名
            if (parseInt(emp.cardStatus, 10) === 10) signedInCount += 1;

            // 包含家人報名
            emp.family?.forEach((fam) => {
              if (fam.color === "blue") registeredCount += 1; // 家人已報名
              // if (parseInt(fam.cardStatus, 10) === 10) signedInCount += 1;
            });
          });

          setStats({ registeredCount, leaveCount, notRegisteredCount, signedInCount });
        } else {
          console.error("Failed to fetch employees");
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setLoading(false);
      }
    };

    if (activityId) {
      fetchEmployees();
    }
  }, [activityId]);

  const handleRegister = async (e_id) => {
    try {
      const response = await fetch("/participation-register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`, // 確保攜帶最新 Token
        },
        body: JSON.stringify({ e_id, a_id: activityId, j_status: 0, editor: 1 }),
      });

      if (response.ok) {
        alert("報名成功！");
        setEmployees((prev) =>
          prev.map((emp) =>
            emp.e_id === e_id ? { ...emp, color: "blue", registrationTime: new Date().toLocaleString() } : emp
          )
        );

        // 更新統計數據
        setStats((prevStats) => ({
          ...prevStats,
          registeredCount: prevStats.registeredCount + 1,
          notRegisteredCount: prevStats.notRegisteredCount - 1,
        }));
      } else {
        const error = await response.json();
        alert(error.message);
      }
    } catch (error) {
      console.error("Error registering employee:", error);
      alert("報名失敗，請稍後再試。");
    }
  };

  const toggleFamily = (e_id) => {
    setExpanded((prev) => ({
      ...prev,
      [e_id]: !prev[e_id], // 切換展開或收起狀態
    }));
  };

  const exportToExcel = () => {
    let serialNumber = 1; // 初始化序號
  
    // 假設 employees 是您的資料陣列
    const dataForExport = employees.flatMap((emp) => {
      const empRow = {
        序號: serialNumber++, // 社友的序號
        名稱: emp.e_name,
        社職: emp.position_name || "", // 職稱
        組別: emp.e_set || "", // 組別
        職稱: emp.e_set_position || "", // 職位
        狀態: emp.color === 'blue' ? '已報名' : emp.color === 'red' ? '請假' : '未報名',
        報名時間: emp.registration_time
          ? new Date(emp.registration_time).toLocaleString()
          : "",
        簽到狀況: emp.cardStatus === "10" ? "已簽到" : emp.cardStatus === "11" ? "已簽退" : "未簽到",
        簽到時間: emp.cardTime
          ? new Date(emp.cardTime.replace(" ", "T")).toLocaleString()
          : "", // 如果有簽到時間，格式化時間；否則留空
      };
  
      // 處理家人，家人的序號繼續從總序號中繼承
      const familyRows = emp.family
        ?.filter((fam) => fam.color === 'blue') // 僅匯出已報名的家人
        .map((fam) => ({
          序號: serialNumber++, // 家人的序號，繼續從前一個序號遞增
          名稱: fam.e_name,
          社職: fam.family_position_name || "", // 家人職稱
          組別: fam.e_set || "", // 組別
          職稱: fam.e_set_position || "", // 職位
          狀態: "已報名",
          報名時間: fam.registration_time
            ? new Date(fam.registration_time).toLocaleString()
            : "",
          簽到狀況: "", // 家人簽到狀況為空（如有需要可擴展後端數據支持）
          簽到時間: "", // 家人簽到時間為空
        })) || [];
  
      return [empRow, ...familyRows];
    });
  
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, '活動報名情況');
  
    // 設定日期格式
// const dateColumns = [6, 8]; // 報名時間和簽到時間在第6列和第8列（索引從0開始）
// const range = XLSX.utils.decode_range(worksheet['!ref']);
// for (let colIndex of dateColumns) {
//   for (let row = range.s.r + 1; row <= range.e.r; row++) {
//     const cellAddress = XLSX.utils.encode_cell({ r: row, c: colIndex });
//     const cell = worksheet[cellAddress];
//     if (cell && cell.v) {
//       const parsedDate = new Date(cell.v);
//       if (!isNaN(parsedDate)) {
//         // 確保日期有效
//         cell.t = 'd'; // 設定單元格類型為日期
//         cell.z = 'yyyy-mm-dd hh:mm:ss'; // 設定日期格式
//         cell.v = parsedDate; // 將值轉換為 Date 對象
//       } else {
//         // 如果日期無效，清空該欄位
//         cell.v = null;
//       }
//     }
//   }
// }

  
    XLSX.writeFile(workbook, `活動_${activityName}_報名情況.xlsx`);
  };
  

  const renderEmployees = (employees) => {
    // 排序邏輯：已報名 -> 請假 -> 未報名
    const sortedEmployees = [...employees].sort((a, b) => {
      const statusPriority = {
        blue: 1, // 已報名
        red: 2,  // 請假
        black: 3, // 未報名 (默認顏色)
      };
  
      const priorityA = statusPriority[a.color] || 3;
      const priorityB = statusPriority[b.color] || 3;
  
      return priorityA - priorityB;
    });
  
    return sortedEmployees.map((emp, index) => (
      <Card className="mb-3 shadow-sm" key={emp.e_id}>
        <Card.Body className="d-flex justify-content-between align-items-center">
          <div>
            <h5>
            <Badge bg="secondary" className="me-2">
  {index + 1} {/* 顯示序號 */}
</Badge>
<span style={{ color: emp.color || "black" }}>
  {emp.e_name}{" "}
  {emp.position_name && (
                <span className="text-muted">-{emp.position_name}</span>
              )} {/* 顯示社職 */}
  {emp.color === "blue" && (
    <>
      <Badge bg="primary" className="me-2">
        報名 {emp.family_registered_count > 0 ? `+${emp.family_registered_count}` : "+0"}
      </Badge>
      {parseInt(emp.cardStatus, 10) === 10 && (
        <Badge bg="success" className="me-2">
          已簽到
        </Badge>
      )}
      {parseInt(emp.cardStatus, 10) === 11 && (
        <Badge bg="warning" className="me-2">
          已簽退
        </Badge>
      )}
    </>
  )}
  {emp.color === "red" && <Badge bg="danger">請假</Badge>}
</span>
            </h5>
           
            <p className="text-muted">報名時間：{emp.registration_time ? new Date(emp.registration_time).toLocaleString() : "未報名"}</p>
  
            {/* 簽到資訊 */}
            <p className="text-muted">
  {parseInt(emp.cardStatus, 10) === 10
    ? `簽到時間：${new Date(emp.cardTime).toLocaleString()}`
    : parseInt(emp.cardStatus, 10) === 11
    ? `簽退時間：${new Date(emp.cardTime).toLocaleString()}`
    : "未簽到"}
</p>
          </div>
          <div>
            {/* <Button
              variant="outline-primary"
              size="sm"
              onClick={() => handleRegister(emp.e_id)}
              className="me-2"
            >
              報名
            </Button> */}
            <Button
              variant="link"
              size="sm"
              onClick={() => toggleFamily(emp.e_id)}
            >
              {expanded[emp.e_id] ? (
                <>
                  收起家人 <FaChevronUp />
                </>
              ) : (
                <>
                  家人 <FaChevronDown />
                </>
              )}
            </Button>
          </div>
        </Card.Body>
        <Collapse in={expanded[emp.e_id]}>
          <Card.Body className="bg-light">
            {emp.family?.length > 0 ? (
              emp.family.map((fam) => (
                <div
                  key={fam.e_id}
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <span style={{ color: fam.color || "gray" }}>
                    <FaUserFriends className="me-2" />
                    {fam.e_name}
                  </span>
                  <p className="text-muted">
                    報名時間：{fam.registrationTime || "尚未報名"}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-muted">沒有家人資料</p>
            )}
          </Card.Body>
        </Collapse>
      </Card>
    ));
  };
  
  return (
    <Container className="mt-4 bg-dark text-light">
      <Card className="bg-dark text-light">
        <Card.Header className="bg-primary text-white text-center">
          <h2>活動報名</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} className="text-center mb-3">
              <p>
                <strong>活動編號：</strong>
                {activityId} - {activityName}
              </p>
              </Col>
            <Col xs={12} className="text-center mb-3">
              <p>
                <strong>統計：</strong>{" "}
                已報名：{stats.registeredCount} | 請假：{stats.leaveCount} | 未報名：{stats.notRegisteredCount} | 已簽到：{stats.signedInCount}
              </p>
            </Col>
            <Col xs={12} className="text-center mb-3">
              <Button variant="success" onClick={exportToExcel}>
                匯出 Excel
              </Button>
            </Col>
          </Row>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <p className="mt-2">加載中...</p>
            </div>
          ) : (
            <Row>
              <Col xs={12}>
                <h4 className="text-center mb-4">社友列表</h4>
                {renderEmployees(employees)}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default JoinPage;
