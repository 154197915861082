import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

function useFetch(url, options = {}, deps = []) {
  const [data, setData] = useState([]); // 初始值設為空陣列
  const [loading, setLoading] = useState(false); // 請求的加載狀態
  const [error, setError] = useState(null); // 請求的錯誤資訊

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios({
        method: options.method || 'GET', // 默認為 GET
        url,
        headers: options.headers || {},
        data: options.body || null, // 如果是 POST 或 PUT，可以傳遞 body 資料
        params: options.params || {}, // 支援查詢參數
      });
      // 修正這裡的 setData，解析可能的資料結構
      setData(response.data.data || response.data || []);
    } catch (err) {
      setError(err.response ? err.response.data : err.message); // 儲存錯誤訊息
    } finally {
      setLoading(false);
    }
  }, [url, JSON.stringify(options)]); // 當 url 或 options 改變時重新觸發請求

  useEffect(() => {
    fetchData();
  }, deps);

  return { data, loading, error, refetch: fetchData }; // 返回資料和狀態
}

export default useFetch;
