import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext';
import { hasPermission } from './utils/permissions';
import debounce from 'lodash.debounce';

// 建立 axios 實例，設置預設的 Authorization 標頭
const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

const LecturerTable = () => {
  // 設定狀態變數
  const [lecturers, setLecturers] = useState([]); // 講師清單
  const [categories, setCategories] = useState([]); // 類別清單
  const [loading, setLoading] = useState(false); // 加載狀態
  const [filter, setFilter] = useState(''); // 搜尋過濾
  const [page, setPage] = useState(1); // 當前頁碼
  const [sortConfig, setSortConfig] = useState({ key: 'l_id', direction: 'desc' }); // 排序設定
  const { user } = useAuth();
  const [canEditRole, setCanEditRole] = useState(false);
  const [hasMore, setHasMore] = useState(true); // 是否有更多資料
  const [showModal, setShowModal] = useState(false); // Modal 顯示狀態
  const [selectedLecturer, setSelectedLecturer] = useState(null); // 選中的講師資料
  
  const [successMessage, setSuccessMessage] = useState(''); // 成功訊息
  const [formData, setFormData] = useState({}); // 表單資料
  const observer = useRef(); // IntersectionObserver 參考
  const lastRef = useRef(); // 最後一個表格項目參考
  
  

      // 在 useEffect 中檢查權限
    useEffect(() => {
      const checkPermission = async () => {
        const permissionGranted = await hasPermission(user, 'EDIT_ROLE');
        setCanEditRole(permissionGranted);
      };
      checkPermission();
    }, [user]);

  // 字段配置，用於動態生成表格和表單欄位
  const fieldConfig = [
    { label: '編號', key: 'l_id' },
    { label: '類別', key: 'lm_name' },
    { label: '講師姓名', key: 'l_name' },
    { label: '經驗', key: 'l_experience' },
    { label: '目前職位', key: 'l_currentpost' },
    { label: '性別', key: 'l_sex' },
    { label: '電話', key: 'l_tel' },
    { label: '傳真', key: 'l_fax' },
    { label: '手機', key: 'l_mobile' },
    { label: '備註', key: 'l_remark' }
  ];

  // 初始化載入講師類別
  useEffect(() => { fetchLecturerCategories(); }, []);

  // 使用 debounce 延遲呼叫 fetchLecturers，防止頻繁請求
  const debounceFetchLecturers = useCallback(debounce(() => fetchLecturers(), 300), [page, filter, sortConfig]);
  useEffect(() => { debounceFetchLecturers(); }, [page, filter, sortConfig]);

  // 從後端獲取講師類別
  const fetchLecturerCategories = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/api/lecturer-categories');
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching lecturer categories:', error);
    }
  }, []);

  // 從後端獲取講師清單，支援分頁和篩選條件
  const fetchLecturers = useCallback(async () => {
    if (loading) return;
    setLoading(true); // 設定為加載中
    try {
      const response = await axiosInstance.get('/api/lecturers', {
        params: { filter, page, sortKey: sortConfig.key, sortDirection: sortConfig.direction },
      });
      setLecturers((prev) => page === 1 ? response.data : [...prev, ...response.data]); // 判斷是否為第一頁
      setHasMore(response.data.length > 0); // 檢查是否有更多資料
    } catch (error) {
      console.error('Error fetching lecturers:', error);
    } finally {
      setLoading(false); // 結束加載狀態
    }
  }, [page, filter, sortConfig]);

  // 排序處理函數
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
    resetData(); // 重置資料
  };

  // 重置資料及頁碼
  const resetData = () => {
    setLecturers([]);
    setPage(1);
  };

  // 刪除講師
  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這位講師嗎？')) {
      try {
        const response = await axiosInstance.delete(`/api/lecturers/${id}`);
        setLecturers((prev) => prev.filter((lecturer) => lecturer.l_id !== id));
        showMessage(response.data.message); // 顯示成功訊息
      } catch (error) {
        console.error('Error deleting lecturer:', error);
      }
    }
  };

  // 儲存或更新講師
  const handleSave = async () => {
    try {
      const url = selectedLecturer ? `/api/lecturers/${selectedLecturer.l_id}` : '/api/lecturers';
      const method = selectedLecturer ? 'put' : 'post';
      const response = await axiosInstance[method](url, formData);
      setLecturers((prev) =>
        selectedLecturer
          ? prev.map((lecturer) => (lecturer.l_id === selectedLecturer.l_id ? response.data : lecturer))
          : [...prev, response.data]
      );
      showMessage(response.data.message); // 顯示成功訊息
      setShowModal(false); // 關閉 Modal
    } catch (error) {
      alert('儲存講師信息時出現錯誤，請稍後重試');
    }
  };

  // 顯示成功訊息，3 秒後自動消失
  const showMessage = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(''), 3000);
  };

  // 開啟或關閉 Modal
  const openModal = (lecturer = null) => {
    setSelectedLecturer(lecturer);
    setFormData(lecturer || {});
    setShowModal(true);
  };

  // 設置 IntersectionObserver 以實現無限滾動
  useEffect(() => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    });
    if (lastRef.current) observer.current.observe(lastRef.current);
  }, [hasMore, loading]);

  // 渲染表格和表單
  return (
    <div className="container-fluid mt-4">
      {successMessage && <div className="alert alert-success">{successMessage}</div>}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋講師資料..."
          value={filter}
          onChange={(e) => { setFilter(e.target.value); resetData(); }}
        />
        {canEditRole && (
          <Button variant="success" size="sm" onClick={() => openModal()}>新增</Button>
        )}
      </div>

      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            {fieldConfig.slice(0, 5).map(({ label, key }, idx) => (
              <th key={idx} onClick={() => handleSort(key)}>{label}</th>
            ))}
            {canEditRole && (
              <>
                <th onClick={() => handleSort('l_tel')}>電話</th>
                <th onClick={() => handleSort('l_mobile')}>手機</th>
                <th>操作</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {lecturers.map((lecturer, index) => (
            <tr key={lecturer.l_id} ref={index === lecturers.length - 1 ? lastRef : null}>
              {fieldConfig.slice(0, 5).map(({ key }) => (
                <td key={key}>{lecturer[key]}</td>
              ))}
              {canEditRole && (
                <>
                  <td>{lecturer.l_tel}</td>
                  <td>{lecturer.l_mobile}</td>
                  <td>
                    <Button variant="info" size="sm" onClick={() => openModal(lecturer)}>編輯</Button>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(lecturer.l_id)}>刪除</Button>
                  </td>
                </>
              )}
            </tr>
          ))}
          {loading && <tr><td colSpan="8" className="text-center">加載中...</td></tr>}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedLecturer ? '編輯講師' : '新增講師'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* 類別下拉選單 */}
            <Form.Group className="mb-3">
              <Form.Label>講師類別</Form.Label>
              <Form.Control
                as="select"
                value={formData.l_group || ''}
                onChange={(e) => setFormData({ ...formData, l_group: e.target.value })}
              >
                <option value="">選擇類別</option>
                {categories.map((category) => (
                  <option key={category.lm_id} value={category.lm_id}>
                    {category.lm_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {fieldConfig.slice(2).map(({ label, key }) => (
              <Form.Group className="mb-3" key={key}>
                <Form.Label>{label}</Form.Label>
                <Form.Control
                  type="text"
                  value={formData[key] || ''}
                  onChange={(e) => setFormData({ ...formData, [key]: e.target.value })}
                />
              </Form.Group>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={() => setShowModal(false)}>取消</Button>
          <Button variant="primary" size="sm" onClick={handleSave}>儲存</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default LecturerTable;
