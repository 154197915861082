import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { recordLoginInfo } from './services/recordLoginInfo';
const REACT_APP_LINE_CHANNEL_ID = process.env.REACT_APP_LINE_CHANNEL_ID;
const REACT_APP_LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI;

const LoginPage = () => {
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [lineInfo, setLineInfo] = useState(null); // 假設有 LINE 登入資訊
  const [errorMessage, setErrorMessage] = useState('');  // 儲存錯誤訊息
  const [successMessage, setSuccessMessage] = useState('');  // 儲存成功訊息
  const location = useLocation();  // React Router 中用來獲取當前路徑的 Hook

  useEffect(() => {
    // 解析 URL 中的 `message` 參數
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get('message');
    if (message) {
      setErrorMessage(decodeURIComponent(message));  // 顯示錯誤提示訊息
    }
  }, [location]);

  // 手機號登入處理邏輯
  const handleMobileLogin = async (e) => {
    e.preventDefault();
    //console.log('表單提交中，手機號：', mobile);
    try {
      const response = await axios.post('/api/login/mobile', { mobile });
      console.log('後端返回的數據：', response.data);

      const { user, token, message } = response.data;
      


      localStorage.setItem('jwtToken', token);
      localStorage.setItem('user.loginType', '1'); // 手機號登入類型為 1


      const userInfo = {
        e_id: user.e_id,
        e_ssid: user.e_ssid,
        e_name: user.e_name,
        e_position: user.e_position,
        e_mobile: user.e_mobile,
        token: token,
      };
      console.log('後端返回的結果:', userInfo);
       // 記錄登入資訊
       await recordLoginInfo(userInfo);

      if (user.e_line_id) {
        // 如果已經綁定 LINE，直接顯示成功訊息並跳轉
        setSuccessMessage('登入成功，已綁定 LINE，將跳轉到首頁...');
        window.location.href = `/?token=${token}`;
      } else {
        // 如果未綁定 LINE，詢問用戶是否要綁定
        if (window.confirm("您尚未綁定 LINE，是否進行綁定？")) {
          //console.log('bind line');
          handleLineLogin();
        } else {
          // 使用者選擇不綁定，直接登入
          setSuccessMessage('成功登入，將跳轉到首頁...');
          window.location.href = `/?token=${token}`;
        }
      }
    } catch (error) {
      setErrorMessage('登入失敗，請跟秘書確認手機是否建檔');
      console.error('登入失敗:', error);
    }
  };

  // Email + 密碼登入邏輯
  const handleEmailPasswordLogin = (e) => {
    e.preventDefault();
    //console.log('LoginPage使用 Email 和密碼登入:', email, password);
    // 這裡可以加入你的 Email + 密碼登入處理邏輯
  };

  // LINE 授權登入邏輯
  const handleLineLogin = () => {

    
    const clientId = process.env.REACT_APP_LINE_CHANNEL_ID;
    const redirectUri = encodeURIComponent(process.env.REACT_APP_LINE_REDIRECT_URI);
    
    const state = `mobile_${mobile}`;  // 簡單地將 `mobile` 參數與前綴組合成 URL 安全字串
    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=profile%20openid`;
    window.location.href = lineAuthUrl; // 重定向至 LINE 授權頁面
  };

  


  return (
    <Container className="mt-5">
    <Row className="justify-content-center">
      <Col md={6}>
        <Card>
          <Card.Body>
            <h1 className="text-center mb-4">登入頁面</h1>

            {successMessage && (
              <div className="alert alert-success text-center">
                {successMessage}
              </div>
            )}

            {errorMessage && (
              <div className="alert alert-danger text-center">
                {errorMessage}
              </div>
            )}

            <Form onSubmit={handleMobileLogin}>
              <Form.Group controlId="formMobile">
                <Form.Label>手機號:</Form.Label>
                <Form.Control
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  placeholder="請輸入手機號"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100 mt-3">
                登入
              </Button>
            </Form>

              {/* Email + 密碼登入表單 */}
              {/* <Form onSubmit={handleEmailPasswordLogin} className="mt-4">
                <Form.Group controlId="formEmail">
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="請輸入 Email"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>密碼:</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="請輸入密碼"
                    required
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100 mt-3">
                  登入
                </Button>
              </Form> */}

              <hr />

              {/* 其他登入方式 */}
              <div className="text-center">
                <p>或者使用其他方式登入:</p>
                <Button variant="outline-success" className="w-100 mb-2" onClick={handleLineLogin}>
                  <i className="fab fa-line"></i> LINE 登入
                </Button>
              </div>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
