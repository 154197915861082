import React, { useState, useEffect } from 'react';
import axios from 'axios';

const EDMWall = () => {
  const [employees, setEmployees] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true); // 控制輪播的狀態
  const token = localStorage.getItem('token');

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('/api/Allemployees', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          allData: true, // 傳遞 allData 參數，請求全部資料
        },
      });

      const employeesWithPhotos = (response.data.data || []).filter(employee => employee.EDM_photo);
      setEmployees(employeesWithPhotos);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  // 輪播邏輯
  useEffect(() => {
    let interval;
    if (isPlaying && employees.length > 0) {
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % employees.length);
      }, 3000); // 每 3 秒切換一次圖片
    }
    return () => clearInterval(interval); // 清除定時器
  }, [isPlaying, employees]);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);
  };

  return (
    <div style={{ padding: '10px', textAlign: 'center' }}>
      {/* 自動輪播區域 */}
      {employees.length > 0 && (
        <>
          <div style={{
          width: '100%', // 寬度填滿容器
          maxWidth: '800px', // 最大寬度 800px，適用於電腦螢幕
          aspectRatio: '1 / 1.414', // 設定 A4 比例
          margin: '0 auto 20px', // 水平置中並留出間距
          overflow: 'hidden',
          borderRadius: '8px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        }}>
            <img
              src={`/uploads/EDM/${employees[currentImageIndex].EDM_photo}`}
              alt="輪播圖片"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain', // 確保圖片完整顯示
              }}
            />
          </div>

           {/* 顯示資訊 */}
           <div style={{
            marginBottom: '10px',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '16px', // 資訊文字大小
          }}>
            <span>{employees[currentImageIndex].store_name}</span> |{' '}
            <span>{employees[currentImageIndex].position_title}</span> |{' '}
            <span>{employees[currentImageIndex].e_name}</span>
          </div>
        </>
      )}

      {/* 播放與停止按鈕 */}
      <button
        onClick={togglePlay}
        style={{
          marginTop: '10px',
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '8px',
          border: 'none',
          backgroundColor: isPlaying ? '#f44336' : '#4caf50',
          color: 'white',
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        }}
      >
        {isPlaying ? '停止輪播' : '開始輪播'}
      </button>
    </div>
  );
};

export default EDMWall;
