import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import { useAuth } from './context/AuthContext';
import { hasPermission } from './utils/permissions';

const VisitsTable = () => {
  const { user } = useAuth();
  const [canEditRole, setCanEditRole] = useState(false);
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 'v_id', direction: 'desc' });
  const [showModal, setShowModal] = useState(false);
  const [editVisit, setEditVisit] = useState(null); // 用於編輯時的記錄
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    v_aid: '',
    v_name: '',
    v_sid: '',
    v_ssid: '',
    v_group: '',
    v_remark: '',
    v_date: '',
    v_edit_uid: user ? user.e_id : null,
    v_edit_day: new Date().toISOString().slice(0, 19).replace('T', ' '),
    v_status: 1,
  });

  const observer = useRef();
  const lastVisitRef = useRef();
  

      // 在 useEffect 中檢查權限
    useEffect(() => {
      const checkPermission = async () => {
        const permissionGranted = await hasPermission(user, 'EDIT_ROLE');
        setCanEditRole(permissionGranted);
      };
      checkPermission();
    }, [user]);

  // 加載訪問記錄
  const fetchVisits = async (page, sortKey = 'v_id', sortDirection = 'desc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/visits', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setVisits((prevVisits) => [...prevVisits, ...response.data]);
      setHasMore(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching visits:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchVisits(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);

  // 排序功能
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setVisits([]);
    setPage(1);
    fetchVisits(1, key, direction, filter);
  };

  // 過濾功能
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setVisits([]);
    setPage(1);
    fetchVisits(1, sortConfig.key, sortConfig.direction, e.target.value);
  };

  // 開啟或關閉模態框
  const openModal = (visit = null) => {
    setEditVisit(visit);
    if (visit) {
      setFormData({ ...visit });
    } else {
      setFormData({
        v_aid: '',
        v_name: '',
        v_sid: '',
        v_ssid: '',
        v_group: '',
        v_remark: '',
        v_date: '',
        v_edit_uid: user ? user.e_id : null,
        v_edit_day: new Date().toISOString().slice(0, 19).replace('T', ' '),
        v_status: 1,
      });
    }
    setShowModal(true);
  };

  // 保存或更新訪問記錄
  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (editVisit) {
        // 更新記錄
        await axios.put(`/api/visits/${editVisit.v_id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSuccessMessage("訪問記錄已成功更新！");
      } else {
        // 新增記錄
        const response = await axios.post('/api/visits', formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setVisits((prevVisits) => [...prevVisits, { ...formData, v_id: response.data.id }]);
        setSuccessMessage("訪問記錄已成功新增！");
      }
      setTimeout(() => setSuccessMessage(""), 3000);
      setShowModal(false);
    } catch (error) {
      console.error("Error saving visit:", error);
    }
  };

  // 刪除訪問記錄
  const handleDelete = async (v_id) => {
    if (window.confirm('確定要刪除此訪問記錄嗎？')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/visits/${v_id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setVisits((prevVisits) => prevVisits.filter((visit) => visit.v_id !== v_id));
        setSuccessMessage("訪問記錄已成功刪除！");
        setTimeout(() => setSuccessMessage(""), 3000);
      } catch (error) {
        console.error('Error deleting visit:', error);
      }
    }
  };

  return (
    <div className="container-fluid mt-4">
      {/* 成功訊息 */}
      {successMessage && (
        <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
          {successMessage}
        </Alert>
      )}
      
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋訪問記錄..."
          value={filter}
          onChange={handleFilterChange}
        />
        {canEditRole && (
          <Button variant="success" onClick={() => openModal()}>
            新增
          </Button>
        )}
      </div>

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('v_id')}>編號</th>
            <th onClick={() => handleSort('company_name')}>公司名稱</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('v_name')}>訪問名稱</th>
            <th onClick={() => handleSort('v_date')}>日期</th>
            <th onClick={() => handleSort('v_remark')}>備註</th>
            {canEditRole && (
              <th>操作</th>
          
          )}
          </tr>
        </thead>
        <tbody>
          {visits.map((visit, index) => (
            <tr key={visit.v_id} ref={visits.length === index + 1 ? lastVisitRef : null}>
              <td>{visit.v_id}</td>
              <td>{visit.company_name}</td>
              <td>{visit.store_name}</td>
              <td>{visit.v_name}</td>
              <td>{visit.v_date}</td>
              <td>{visit.v_remark}</td>
              {canEditRole && ( 
                <td>
                  <Button variant="info" size="sm" onClick={() => openModal(visit)}>
                    編輯
                  </Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(visit.v_id)}>
                    刪除
                  </Button>
                </td>
              )}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan={hasPermission(user, "EDIT_ROLE") ? 7 : 6} className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* 新增/編輯模態框 */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{editVisit ? "編輯訪問記錄" : "新增訪問記錄"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>訪問名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.v_name}
                onChange={(e) => setFormData({ ...formData, v_name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>公司名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.v_sid}
                onChange={(e) => setFormData({ ...formData, v_sid: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>社別</Form.Label>
              <Form.Control
                type="text"
                value={formData.v_ssid}
                onChange={(e) => setFormData({ ...formData, v_ssid: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>日期</Form.Label>
              <Form.Control
                type="datetime-local"
                value={formData.v_date}
                onChange={(e) => setFormData({ ...formData, v_date: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>備註</Form.Label>
              <Form.Control
                as="textarea"
                value={formData.v_remark}
                onChange={(e) => setFormData({ ...formData, v_remark: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisitsTable;
