import React, { useState } from 'react';
import axios from 'axios';

const PhotoUpload = ({ e_id, token, fileCategory, onUploadSuccess }) => {
  const [uploadStatus, setUploadStatus] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  const handleFileChange = (event) => {
    setUploadStatus('');
    setSuccessMessage('');
    const file = event.target.files[0];

    if (!file) return;

    if (file.size > 10 * 1024 * 1024) { // 5MB 限制
      setUploadStatus('文件大小不能超過 10MB');
      return;
    }

    // 生成圖片預覽
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    handleFileUpload(file);
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('e_id', e_id);
    formData.append('fileCategory', fileCategory); // 傳遞 fileCategory

    try {
      const response = await axios.post(`/api/upload-photo?e_id=${e_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const filePath = response.data.filePath; // 從後端獲取文件路徑
      const fileName = filePath.split('/').pop(); // 提取文件名

      setSuccessMessage('照片上傳成功！');
      onUploadSuccess(fileName); // 傳遞文件名給父元件
    } catch (error) {
      console.error('照片上傳失敗', error);
      setUploadStatus('照片上傳失敗！');
    }
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} />
      {previewUrl && <img src={previewUrl} alt="預覽" style={{ width: '100px', height: '120px', borderRadius: '8px' }} />}
      {uploadStatus && <div>{uploadStatus}</div>}
      {successMessage && <div>{successMessage}</div>}
    </div>
  );
};

export default PhotoUpload;
