import axios from 'axios';

export const hasPermission = async (user, permission) => {
  if (!user) return false;
  
  const token = localStorage.getItem('token');
  if (!token) {
    console.warn('No token found in localStorage');
    return false;
  }
  //console.log("hasPermission Token:", token);  // 確認 token 是否存在
  try {
    const { data } = await axios.get(`/api/permissions/${permission}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    // 權限檢查邏輯
    const {
      required_loginType,
      required_e_ssid,
      required_e_position,
      required_e_id
    } = data;

    // 檢查各條件是否正確對應
    console.log("User data:", user);
    console.log("required_loginType:", required_loginType, "User loginType:", user.loginType);
    console.log("required_e_ssid:", required_e_ssid, "User e_ssid:", user.e_ssid);
    console.log("required_e_position:", required_e_position, "User e_position:", user.e_position);
    console.log("required_e_id:", required_e_id, "User e_id:", user.e_id);


    const loginTypeCheck = !required_loginType || user.loginType === required_loginType;
    const eSsidCheck = !required_e_ssid || user.e_ssid === required_e_ssid;
    const ePositionCheck = !required_e_position || user.e_position === required_e_position;
    const eIdCheck = !required_e_id || user.e_id === required_e_id;
    console.log("loginTypeCheck:", loginTypeCheck, "eSsidCheck:", eSsidCheck, "ePositionCheck:", ePositionCheck, "eIdCheck:", eIdCheck);
    

    return loginTypeCheck && eSsidCheck && ePositionCheck && eIdCheck;
  } catch (error) {
    if (error.response) {
      console.error('Permission check error:', error.response.data);
    } else if (error.request) {
      console.error('Permission check error: No response received', error.request);
    } else {
      console.error('Error setting up permission request:', error.message);
    }
    return false;
  }
};

