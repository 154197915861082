// src/mockData.js
export const mockData = {
    voltage: 211,
    indoorTemperature: 28,
    outdoorTemperature: 28,
    indoorHumidity: 45,
    outdoorHumidity: 45,
    pm2_5: 110,
    pm10: 220,
    airQualityIndex: 210,
    co2Level: 123,
    flowRate: 21,
    fanState: "On",
  };
  