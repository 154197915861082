import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import PhotoUpload from '../services/PhotoUpload'; // 匯入 PhotoUpload 元件
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import Lightbox from 'react-image-lightbox'; // 匯入 Lightbox
import 'react-image-lightbox/style.css'; // 匯入 Lightbox 的樣式

function Personal() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({});
  const [tableSettings, setTableSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false); // 控制 Lightbox 開啟狀態
  const [lightboxImage, setLightboxImage] = useState(''); // Lightbox 顯示的圖片
  const e_id = user.e_id;
  const token = localStorage.getItem('token');

  // 初始化時讀取社友資料和表格設定
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        // 查詢社友資料
        const employeeResponse = await axios.get(`/api/employees/${e_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setEmployee(employeeResponse.data);

        // 查詢表格設定
        const settingsResponse = await axios.get('/api/employee-table-settings', {
          params: { table_name: 'employees' },
          headers: { Authorization: `Bearer ${token}` },
        });
        setTableSettings(settingsResponse.data);

        setLoading(false);
      } catch (error) {
        console.error('讀取資料失敗:', error);
        setLoading(false);
      }
    };

    fetchEmployeeData();
  }, [e_id, token]);

  // 處理表單變更
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee((prevEmployee) => ({ ...prevEmployee, [name]: value }));
  };

  // 特殊處理 e_foodType 欄位的值變更
  const handleFoodTypeChange = (e) => {
    const value = e.target.value === '葷食' ? 1 : 2;
    setEmployee((prevEmployee) => ({ ...prevEmployee, e_foodType: value }));
  };

   
  // 提交表單更新資料
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(`/api/employees/${e_id}`, employee, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        alert('資料更新成功');
        navigate('-1');
      })
      .catch((error) => {
        console.error('更新失敗:', error);
        alert('更新失敗，請重試');
      });
  };

  if (loading) {
    return <div>資料載入中...</div>;
  }

  // 當照片上傳成功後更新指定的 employee 欄位
const handleUploadSuccess = (fileName, fieldName) => {
  setEmployee((prevEmployee) => ({ ...prevEmployee, [fieldName]: fileName }));
};


  const photoUrl = employee.e_photo
  ? `/uploads/member/${employee.e_photo}?t=${new Date().getTime()}`
  : '/uploads/member/default.jpg';

const EDMphotoUrl = employee.EDM_photo
  ? `/uploads/EDM/${employee.EDM_photo}?t=${new Date().getTime()}`
  : '/uploads/EDM/default.jpg';

  // 內聯樣式：將背景顏色改為白色
const lightModeStyles = {
  backgroundColor: '#FFFFFF',  // 設為白色背景
  color: '#000000',            // 設為黑色文字
  fontFamily: 'Arial, sans-serif',
};

  return (
    <Container maxWidth="sm" style={lightModeStyles}>
      <Typography variant="h4" align="center" gutterBottom>
        個人資料管理
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate>
  <Box display="flex" justifyContent="center" mb={2}>
    <img
      src={photoUrl}
      alt={employee.e_photo || '預設照片'}
      style={{ width: 100, height: 120, borderRadius: 8, cursor: 'pointer' }}
            onClick={() => {
              setLightboxImage(photoUrl);
              setIsLightboxOpen(true);
            }}
    />
  </Box>
  {/* 社友相片上傳模組 */}
  <PhotoUpload
    e_id={e_id}
    token={token}
    fileCategory="member"
    onUploadSuccess={(fileName) => handleUploadSuccess(fileName, 'e_photo')}
  />

        <TextField
          label="社別"
          fullWidth
          value={employee.store_name || ''}
          InputProps={{ readOnly: true }}
          margin="normal"
        />
        <TextField
          label="社團職稱"
          fullWidth
          value={employee.position_title || ''}
          InputProps={{ readOnly: true }}
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>飲食習慣</InputLabel>
          <Select
            value={employee.e_foodType === 1 ? '葷食' : '素食'}
            onChange={handleFoodTypeChange}
            //disabled={employee.table_whole_edit !== 1}
          >
            <MenuItem value="葷食">葷食</MenuItem>
            <MenuItem value="素食">素食</MenuItem>
          </Select>
        </FormControl>
        {tableSettings.map((setting) => {
          const { table_title_eng, table_title_cht, table_whole_edit, table_view } = setting;
          return table_view === 0 ? (
            <TextField
              key={table_title_eng}
              label={table_title_cht}
              fullWidth
              name={table_title_eng}
              value={employee[table_title_eng] || ''}
              onChange={handleChange}
              margin="normal"
              disabled={table_whole_edit !== 0}
            />
          ) : null;
        })}
         <Box display="flex" justifyContent="center" mt={4} mb={2}>
          <img
            src={EDMphotoUrl}
            alt={employee.EDM_photo || '預設照片'}
            style={{ width: 100, height: 120, borderRadius: 8, cursor: 'pointer' }}
            onClick={() => {
              setLightboxImage(EDMphotoUrl);
              setIsLightboxOpen(true);
            }}
          />
        </Box>
        {/* EDM相片上傳模組 */}
        <PhotoUpload
          e_id={e_id}
          token={token}
          fileCategory="EDM"
          onUploadSuccess={(fileName) => handleUploadSuccess(fileName, 'EDM_photo')}
        />
        
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button type="submit" variant="contained" color="primary">
            儲存變更
          </Button>
          <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
            返回
          </Button>
        </Box>
      </Box>
      {/* Lightbox 顯示區域 */}
      {isLightboxOpen && (
        <Lightbox
          mainSrc={lightboxImage}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </Container>
  );
}

export default Personal;
