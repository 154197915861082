import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { MarkerClusterer } from '@react-google-maps/api';
import axios from 'axios';

const MapComponent = () => {
  const [currentLocation, setCurrentLocation] = useState({ lat: 23.7147, lng: 120.5390 });
  const [selected, setSelected] = useState(null);
  const [locations, setLocations] = useState([]);

  // 取得 Token
  const token = localStorage.getItem('token');
  //console.log('IMC-map.jsToken:', token);
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Geolocation error:", error);
        },
        { enableHighAccuracy: true }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    // 帶上 JWT Token 發送 API 請求
    axios.get('api/locations', {
      headers: {
        'Authorization': `Bearer ${token}` // 添加 Authorization 頭
      }
    })
    .then(response => {
      setLocations(response.data);
    })
    .catch(error => {
      console.error("Error fetching location data:", error);
    });
  }, [token]); // 當 token 變更時重新發送請求

  useEffect(() => {
    const socket = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/ws`);
  
    socket.onopen = () => {
      console.log('WebSocket connection opened');
    };
  
    socket.onmessage = (event) => {
      console.log('Received:', event.data);
    };
  
    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };
  
    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  
    return () => {
      socket.close();
    };
  }, []);
  

  const mapStyles = {
    height: "100vh",
    width: "100%"
  };

  return (
    <LoadScript googleMapsApiKey='AIzaSyDgVylSVIknzV17Z48u0gc7grLdHCIFdXc'>
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={12}
        center={currentLocation}
      >
        <MarkerClusterer>
          {(clusterer) =>
            locations.map((location, index) => (
              <Marker
                key={location.e_id}
                position={{ 
                  lat: location.m_lat + (index * 0.00000008), // 將經緯度略微偏移
                  lng: location.m_lng
                }}
                clusterer={clusterer} // 保留 MarkerClusterer 的功能
                onClick={() => setSelected(location)}
              />
            ))
          }
        </MarkerClusterer>

        {selected && (
          <InfoWindow
            position={{ lat: selected.m_lat, lng: selected.m_lng }}
            onCloseClick={() => setSelected(null)}
          >
            <div style={{ width: '250px', padding: '10px', textAlign: 'center' }}>
              {selected.e_photo && (
                <img 
                  src={`https://oneimc.li.com.tw/uploads/member/${selected.e_photo}`} 
                  alt={selected.e_name} 
                  style={{ width: '100px', height: '120px', borderRadius: '8px', marginBottom: '10px' }} 
                />
              )}
              <h2>{selected.s_ss_name}</h2>
              <h2>{selected.g_name}</h2>
              <h1>{selected.e_name}</h1>
              <h2>
              <a 
              href={`https://www.google.com.tw/maps/place/${selected.m_lat},${selected.m_lng}`} 
              target="_blank" 
              rel="noopener noreferrer"
              >導航
              </a>
              </h2>
              <h2><a href={`tel:${selected.e_mobile}`}>撥號</a></h2>
              {selected.e_comp_web ? (
                <h2>
                  <a 
                    href={selected.e_comp_web.startsWith('http://') || selected.e_comp_web.startsWith('https://') ? selected.e_comp_web : `http://${selected.e_comp_web}`}
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    {selected.e_comp_name}
                  </a>
                </h2>
              ) : (
                <h2>{selected.e_comp_name}</h2>
              )}
              <h2>{selected.e_comp_position}</h2>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </LoadScript>
  );
}

export default MapComponent;
