import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'; // 用來獲取路由參數
import { useAuth } from './context/AuthContext'; // 假設你的 AuthContext 位於這個路徑

const ParticipationsTable = () => {
  const [participations, setParticipations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const lastParticipationRef = useRef();
  const [sortConfig, setSortConfig] = useState({ key: 'workDate', direction: 'desc' });
  const observer = useRef(); // observer 用於監聽滾動事件
  const { user } = useAuth(); // 取得使用者資訊
  const location = useLocation(); // 用來獲取當前路由的查詢參數
  const queryParams = new URLSearchParams(location.search);
  const isSelf = queryParams.get('self') === 'true'; // 如果路由中有 `self=true`，顯示自己的活動記錄

  useEffect(() => {
    // 初始化數據
    fetchParticipations(1, sortConfig.key, sortConfig.direction, filter);
  }, [filter, sortConfig, isSelf]); // 添加 `isSelf` 作為依賴

  useEffect(() => {
    // 滾動監聽邏輯
    if (loading || !hasMore) return;
    if (observer.current) observer.current.disconnect(); // 防止重複觀察

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後加載下一頁
      }
    });

    if (lastParticipationRef.current) {
      observer.current.observe(lastParticipationRef.current); // 監聽最後一個參考元素
    }
  }, [loading, hasMore]);

  useEffect(() => {
    if (page > 1) {
      fetchParticipations(page, sortConfig.key, sortConfig.direction, filter); // 分頁加載
    }
  }, [page]);
  //console.log(user); // 確認 user 是否正確包含 e_id

  const fetchParticipations = async (page, sortKey = 'rid', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/participations', {
        params: { filter, page, sortKey, sortDirection, self: isSelf, eid: user?.e_id }, // 傳遞使用者的 ID
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setParticipations((prevParticipations) => [...prevParticipations, ...response.data.participations]);
      setHasMore(response.data.participations.length > 0); // 確定是否還有更多數據
    } catch (error) {
      console.error('Error fetching participations:', error);
    }
    setLoading(false);
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setParticipations([]); // 清空當前數據
    setPage(1); // 重置頁碼
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setParticipations([]); // 清空參與記錄
    setPage(1); // 重置頁碼
  };

  return (
    <div className="container-fluid mt-4">
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋參與記錄..."
          value={filter}
          onChange={(e) => {
            setParticipations([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
      </div>

      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('rid')}>編號</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('e_name')}>姓名</th>
            <th onClick={() => handleSort('a_name')}>活動名稱</th>
            <th onClick={() => handleSort('workDate')}>報名日期</th>
            <th onClick={() => handleSort('cardTime')}>時間記錄</th>
          </tr>
        </thead>
        <tbody>
          {participations.map((participation, index) => {
            return (
              <tr key={participation.rid} ref={participations.length === index + 1 ? lastParticipationRef : null}>
                <td>{participation.rid}</td>
                <td>{participation.store_name}</td>
                <td>{participation.e_name}</td>
                <td>{participation.a_name}</td>
                <td>{participation.workDate}</td>
                <td>{participation.cardTime}</td>
              </tr>
            );
          })}
          {loading && (
            <tr>
              <td colSpan="6" className="text-center">加載中...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ParticipationsTable;
