import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Table, Form, Alert, Button, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 確認 `useAuth` 已正確導入
import Select from 'react-select'; // 匯入 react-select
import { downloadExcel } from './utils/excel'; // 導入 downloadExcel 函數

function EmployeeBatch() {
  const [tableData, setTableData] = useState([]);
  const [appellations, setAppellations] = useState([]);
  const [employees, setEmployees] = useState([]); // 新增一個狀態來儲存社友列表
  const [mainempOptions, setMainempOptions] = useState([]); // 儲存 mainemp 表中的類別
  const [filter, setFilter] = useState('');
  const [filterGroup, setFilterGroup] = useState('');
  //const [filterStatus, setFilterStatus] = useState(''); // 狀態篩選
  //const [filterPosition, setFilterPosition] = useState(''); // 職位篩選
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'asc' });
  const [message, setMessage] = useState('');
  const [positions, setPositions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ e_appellation: '',e_group: '' }); // 初始化為空物件並設置 `e_appellation` 預設值
  const [isEdit, setIsEdit] = useState(false);
  const inputRefs = useRef({}); // 儲存所有輸入框的 ref
  const { user } = useAuth(); // 使用 useAuth 來取得使用者資訊
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  

  const token = localStorage.getItem('token');
  //console.log('batch',token)

  useEffect(() => {
    const fetchMainempOptions = async () => {
      try {
        const response = await axios.get('/api/mainemp', {
          headers: { Authorization: `Bearer ${token}` },
        });
        // 過濾 e_group 為 4 以下的選項
        const filteredOptions = response.data.filter(option => 
          option.em_id >= 4
        );
        setMainempOptions(filteredOptions);
      } catch (error) {
        console.error('Error fetching mainemp options:', error);
      }
    };
    fetchMainempOptions();
  }, [token]);

  useEffect(() => {
    const fetchAppellations = async () => {
      try {
        const response = await axios.get('/api/appellations');
        setAppellations(response.data);
        //console.log("Appellations data:", response.data); // 打印返回的資料
      } catch (error) {
        console.error('Error fetching appellations:', error);
      }
    };
  
    fetchAppellations();
  }, []);

  // 處理表單變更
  
  
  useEffect(() => {
    if (modalData?.e_photo) {
      setPreviewUrl(`/uploads/member/${modalData.e_photo}?t=${new Date().getTime()}`); // 使用時間戳避免快取
    } else {
      setPreviewUrl('/uploads/member/default.jpg'); // 預設圖片
    }
  }, [modalData?.e_photo]);
    

  useEffect(() => {
    fetchData();
    fetchPositions();
  }, [filter, sortConfig, filterGroup]); // 添加篩選條件依賴

  // 获取职位列表
  const fetchPositions = () => {
    axios
      .get('/api/positions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setPositions(response.data);
      })
      .catch((error) => {
        console.error('Error fetching positions:', error.response || error);
      });
  };

  const fetchData = () => {
    axios
      .get('/api/employees1', {
        params: {
          filter,               // 搜索的篩選
          sortKey: sortConfig.key,
          sortDirection: sortConfig.direction,
          filterGroup,         // 狀態篩選
          
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let data = response.data.data;
         // 過濾資料（如需過濾社友）
      // if (filterStatus === '0') { // 0代表只顯示社友
      //   data = data.filter((employee) => employee.e_status === 0);
      // }

      setEmployees(data); // 將資料設置到 employees 狀態
   // 过滤数据
   if (filter) {
    const lowerFilter = filter.toLowerCase();
    data = data.filter((employee) =>
      Object.values(employee).some((val) =>
        String(val).toLowerCase().includes(lowerFilter)
      )
    );
  }



  // 排序数据
  if (sortConfig.key) {
    data = data.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
 
        // 這裡可以移除前端的篩選邏輯，因為後端已經處理了這些篩選條件
        // 你只需要檢查後端返回的數據是否正確
  
        setTableData(data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error.response || error);
      });
  };
  
const employeeOptions = employees.map((employee) => ({
  value: employee.e_id,
  label: employee.e_name,
}));

  const handleSave = (id) => {
    const rowData = tableData.find((row) => row.e_id === id);
    if (!rowData) {
      console.error('Row data not found for id:', id);
      return;
    }

    if (!token) {
      setMessage('未找到驗證信息，請重新登錄');
      return;
    }

    axios
      .put(`/api/employees/${id}`, rowData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        setMessage('更新成功！');
        setTableData((prevData) =>
          prevData.map((row) =>
            row.e_id === id ? { ...row, hasChanges: false, saveStatus: 'saved' } : row
          )
        );
        setTimeout(() => setMessage(''), 2000); // 2 秒後自動隱藏訊息
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          setMessage(error.response.data.message);
        } else {
          console.error('Error updating data:', error);
          setMessage('更新失敗！');
        }
      });
  };

  const handleInputChange = (e, id, field, newValue = null) => {
    const value = newValue !== null ? newValue : e.target.value;
    //console.log(`Updating field ${field} for id ${id} with value: ${value}`);

    const updatedData = tableData.map((row) => {
      if (row.e_id === id) {
        let updatedRow = {
          ...row,
          [field]: value,
          hasChanges: true,
          saveStatus: 'unsaved',
        };
        if (field === 'position_title') {
          const selectedOption = positions.find((option) => option.s_name === value);
          updatedRow.e_position = selectedOption ? selectedOption.s_id : null;
        }
        return updatedRow;
      }
      return row;
    });

    setTableData(updatedData);
  };

  const handleStatusToggle = (id) => {
    const employee = tableData.find((row) => row.e_id === id);
    if (employee) {
      const newStatus = employee.e_status === 1 ? 0 : 1;
      handleInputChange(null, id, 'e_status', newStatus);
    }
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' ↑' : ' ↓';
    }
    return '';
  };

  const handleOpenModal = (employee = null) => {
    const initialData = employee || { 
      e_name: '', 
      position_title: '', 
      e_set: '', 
      e_set_position: '', 
      e_mobile: '', 
      e_address2: '', 
      e_status: 1, 
      e_photo: '', // 確保有 e_photo 字段
      e_ssid: user?.e_ssid || '', // 新增時使用從 useAuth 中取得的 e_ssid
      e_group: '' // 新增時預設 e_group 為 5
    };
    
    setModalData(initialData);
    setIsEdit(!!employee);
    setShowModal(true);

    // 如果有現有的 e_photo，設置為圖片預覽
  if (initialData.e_photo) {
    setPreviewUrl(`/${initialData.e_photo}`); // 假設圖片是存儲在 /uploads 目錄中
  } else {
    setPreviewUrl(null); // 如果沒有 e_photo，清除預覽
  }
};
  const handleSaveOrUpdate = async () => {
    const cleanedFormData = Object.fromEntries(
      Object.entries(modalData).map(([key, value]) => [key, value === '' ? null : value])
    );
  
    if (!token) {
      setMessage('未找到驗證信息，請重新登錄');
      return;
    }
  
    try {
      if (isEdit) {
        // 編輯現有的社友
        await axios.put(`/api/employees/${modalData.e_id}`, cleanedFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMessage('更新成功！');
      } else {
        // 新增新社友
        await axios.post('/api/employees', cleanedFormData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMessage('新增成功！');
      }
  
      // 更新表格數據並關閉彈窗
      setShowModal(false);
      fetchData(); // 更新整個表格數據
  
      // 2 秒後清除成功消息
      setTimeout(() => setMessage(''), 2000);
    } catch (error) {
      if (error.response) {
        console.error(isEdit ? 'Error updating data:' : 'Error adding data:', error.response.data);
        setMessage(`${isEdit ? '更新' : '新增'}失敗: ${error.response.data.message}`);
      } else {
        console.error(isEdit ? 'Error updating data:' : 'Error adding data:', error);
        setMessage(`${isEdit ? '更新' : '新增'}失敗！`);
      }
    }
  };

  const handleFileChange = (event) => {

    setUploadStatus('');
    setSuccessMessage('');
    const file = event.target.files[0];

    
    if (!file) return;

    if (file.size > 10 * 1024 * 1024) { // 5MB 限制
      setUploadStatus('文件大小不能超过 5MB');
      return;
    }

    setSelectedFile(file);

    // 使用 FileReader 生成圖片預覽
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result); // 設置圖片預覽
    };
    reader.readAsDataURL(file); // 將文件讀取為 base64 編碼
  };

  // 文件上傳處理
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const formData = new FormData();
    //console.log('e_id before upload:', modalData.e_id);

    formData.append('photo', file);
    formData.append('e_id', modalData.e_id); // 確保將 e_id 一同附加到 FormData
    formData.append('fileCategory', 'member'); // 你可以传递 'member' 或 'EDM' 根据需要改变

    try {
      const response = await axios.post(`/api/upload-photo?e_id=${modalData.e_id}`,  formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      const filePath = response.data.filePath; // 從後端獲取文件路徑
      const fileName = filePath.split('/').pop(); // 提取文件名
      console.error('path',filePath);
      console.error('path',fileName);

      // 更新 modalData 的 e_photo 字段
      setModalData((prevData) => ({
        ...prevData,
        e_photo: fileName,
      }));

      setSuccessMessage('照片上傳成功！');
    } catch (error) {
      console.error('照片上傳失敗', error);
      setUploadStatus('照片上傳失敗！');
    }
  };
  
  

  return (
    <div className="container-fluid mt-4">
       {/* 顯示成功消息 */}
    {successMessage && (
      <div className="alert alert-success" role="alert">
        {successMessage}
      </div>
    )}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋姓名、手機或組別..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ padding: '10px', width: '100%' }}
        />
        <Button onClick={() => handleOpenModal()}className="mb-3">新增</Button>
         {/* 下載 Excel 按鈕 */}
         <Button onClick={() => {
    if (tableData && tableData.length > 0) {
        downloadExcel(tableData, 'employees', `資料_${new Date().toISOString().slice(0, 10)}.xlsx`);
    } else {
        alert('沒有資料可供下載');
    }
}} className="mb-3">
    下載
</Button>

      </div>
      

      {/* 狀態和職位篩選 */}
      <div className="d-flex mb-3">
        <Form.Select
          value={filterGroup}
          onChange={(e) => setFilterGroup(e.target.value)}
        >
          <option value="">篩選社友類別</option>
          {mainempOptions.map((option) => (
            <option key={option.em_id} value={option.em_id}>
              {option.em_name}
            </option>
          ))}
        </Form.Select>
      </div>


      {message && (
        <Alert variant="success" onClose={() => setMessage('')} dismissible>
          {message}
        </Alert>
      )}

      <div className="table-responsive">
        <Table striped bordered hover variant="dark" className="table-striped table-dark">
          <thead>
            <tr>
            <th style={{ width: '10px' }} onClick={() => handleSort('id')}>
                ID {renderSortIcon('id')}
              </th>
              <th style={{ width: '10px' }}>相片</th> {/* 新增相片欄位 */}
              <th style={{ width: '150px' }} onClick={() => handleSort('e_name')}>
                姓名 {renderSortIcon('e_name')}
              </th>
              <th style={{ width: '150px' }} onClick={() => handleSort('position_title')}>
                職位 {renderSortIcon('position_title')}
              </th>
              <th style={{ width: '150px' }} onClick={() => handleSort('e_set')}>
                組別 {renderSortIcon('e_set')}
              </th>
              <th style={{ width: '150px' }} onClick={() => handleSort('e_set_position')}>
                組別職稱 {renderSortIcon('e_set_position')}
              </th>
              <th style={{ width: '100px' }} onClick={() => handleSort('e_mobile')}>
                手機 {renderSortIcon('e_mobile')}
              </th>
              <th style={{ width: '200px' }} onClick={() => handleSort('e_address2')}>
                通訊地址 {renderSortIcon('e_address2')}
              </th>
              <th style={{ width: '50px' }} onClick={() => handleSort('e_status')}>
                狀態 {renderSortIcon('e_status')}
              </th>
              <th style={{ width: '100px' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((employee, index) => (
              <tr key={employee.e_id}>
                <td>{index + 1}</td>
                <td>
                <img
                  src={
                    employee.e_photo
                      ? `/uploads/member/${employee.e_photo}`
                      : '/uploads/member/default.jpg'
                  }
                  alt="社友相片"
                  style={{ width: '60px', height: '80px'}}
                />
              </td>
                <td>
                  <input
                    type="text"
                    value={employee.e_name || ''} // Ensures `value` is not `null`
                    onChange={(e) => handleInputChange(e, employee.e_id, 'e_name')}
                    className="form-control"
                  />
                </td>
                <td>
                  <Form.Select
                    value={employee.position_title || ''}
                    onChange={(e) => handleInputChange(e, employee.e_id, 'position_title')}
                    className="form-control"
                  >
                    <option value="">請選擇職位</option>
                    {positions.map((position) => (
                      <option key={position.s_id} value={position.s_name}>
                        {position.s_name}
                      </option>
                    ))}
                  </Form.Select>
                </td>
                <td>
                  <input
                    type="text"
                    value={employee.e_set || ''} // 確保 `value` 不為 `null`
                    onChange={(e) => handleInputChange(e, employee.e_id, 'e_set')}
                    className="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={employee.e_set_position || ''} // 確保 `value` 不為 `null`
                    onChange={(e) => handleInputChange(e, employee.e_id, 'e_set_position')}
                    className="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={employee.e_mobile || ''} // 確保 `value` 不為 `null`
                    onChange={(e) => handleInputChange(e, employee.e_id, 'e_mobile')}
                    className="form-control"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={employee.e_address2 || ''} // 確保 `value` 不為 `null`
                    onChange={(e) => handleInputChange(e, employee.e_id, 'e_address2')}
                    className="form-control"
                  />
                </td>
                <td>
                  {/* 使用可点击的颜色点切换 e_status */}
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      borderRadius: '50%',
                      backgroundColor: employee.e_status === 1 ? 'red' : 'green',
                      margin: 'auto',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleStatusToggle(employee.e_id)}
                  ></div>
                </td>
                <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  style={{
                    backgroundColor: employee.saveStatus === 'saved' ? 'green' : employee.hasChanges ? 'red' : 'gray',
                    color: 'white',
                    padding: '5px 10px',
                    fontSize: '12px',
                    border: 'none',
                    minWidth: '60px'
                  }}
                  onClick={() => handleSave(employee.e_id)}
                >
                  儲存
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleOpenModal(employee)}
                  className="ms-2"
                  style={{
                    color: 'white',
                    padding: '5px 10px',
                    fontSize: '12px',
                    minWidth: '60px'
                  }}
                >
                  編輯
                </Button>
              </div>
              </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* 新增/編輯 Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEdit ? '編輯社友' : '新增社友'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group style={{ display: 'none' }}>
              <Form.Label>社別</Form.Label>
              <Form.Control
                type="text"
                name="e_ssid"
                value={modalData?.e_ssid || ''}
                onChange={handleModalInputChange}
                readOnly // 禁止用戶編輯此欄位
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>姓名</Form.Label>
              <Form.Control
                type="text"
                name="e_name"
                value={modalData?.e_name || ''}
                onChange={handleModalInputChange}
              />
            </Form.Group>
            <Form.Group>
            <Form.Label>職位</Form.Label>
            <Form.Select
              name="position_title"
              value={modalData?.position_title || (positions.length > 0 ? positions[0].s_name : '')}
              onChange={handleModalInputChange}
            >
              <option value="">請選擇職位</option>
              {positions.map((position) => (
                <option key={position.s_id} value={position.s_name}>
                  {position.s_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
          <Form.Label>性別</Form.Label>
          <Form.Select
            name="e_sex"
            value={modalData?.e_sex ?? '1'}  // 預設值為 '1' 代表男生
            onChange={handleModalInputChange}
          >
            <option value="1">男生</option>
            <option value="2">女生</option>
          </Form.Select>
        </Form.Group>


        <Form.Group>
  <Form.Label>生日</Form.Label>
  <Form.Control
    type="date"
    name="e_birthday"
    value={modalData?.e_birthday || '2000-01-01'}  // 預設值為 '2000-01-01'
    onChange={handleModalInputChange}
  />
</Form.Group>


<Form.Group>
  <Form.Label>結婚記念日</Form.Label>
  <Form.Control
    type="date"
    name="e_marry"
    value={modalData?.e_marry || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>葷素食</Form.Label>
  <Form.Select
    name="e_foodType"
    value={modalData?.e_foodType || ''}
    onChange={handleModalInputChange}
  >
    <option value="">請選擇</option>
    <option value="1">葷</option>
    <option value="2">素</option>
  </Form.Select>
</Form.Group>


<Form.Group>
  <Form.Label>衣服尺碼</Form.Label>
  <Form.Control
    type="text"
    name="e_size"
    value={modalData?.e_size || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>電話</Form.Label>
  <Form.Control
    type="text"
    name="e_tel"
    value={modalData?.e_tel || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>電話1</Form.Label>
  <Form.Control
    type="text"
    name="e_tel1"
    value={modalData?.e_tel1 || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>傳真</Form.Label>
  <Form.Control
    type="text"
    name="e_fax"
    value={modalData?.e_fax || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>手機</Form.Label>
  <Form.Control
    type="text"
    name="e_mobile"
    value={modalData?.e_mobile || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>住家郵編</Form.Label>
  <Form.Control
    type="text"
    name="e_zip"
    value={modalData?.e_zip || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>住家地址</Form.Label>
  <Form.Control
    type="text"
    name="e_address"
    value={modalData?.e_address || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司郵編</Form.Label>
  <Form.Control
    type="text"
    name="e_zip1"
    value={modalData?.e_zip1 || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司地址</Form.Label>
  <Form.Control
    type="text"
    name="e_address1"
    value={modalData?.e_address1 || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>通訊郵編</Form.Label>
  <Form.Control
    type="text"
    name="e_zip2"
    value={modalData?.e_zip2 || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>通訊地址</Form.Label>
  <Form.Control
    type="text"
    name="e_address2"
    value={modalData?.e_address2 || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>組別</Form.Label>
  <Form.Control
    type="text"
    name="e_set"
    value={modalData?.e_set || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>組別職稱</Form.Label>
  <Form.Control
    type="text"
    name="e_set_position"
    value={modalData?.e_set_position || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>標語</Form.Label>
  <Form.Control
    type="text"
    name="e_slogan"
    value={modalData?.e_slogan || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司名稱</Form.Label>
  <Form.Control
    type="text"
    name="e_comp_name"
    value={modalData?.e_comp_name || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司職稱</Form.Label>
  <Form.Control
    type="text"
    name="e_comp_position"
    value={modalData?.e_comp_position || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司網站</Form.Label>
  <Form.Control
    type="text"
    name="e_comp_web"
    value={modalData?.e_comp_web || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司排序</Form.Label>
  <Form.Control
    type="number"
    name="e_comp_sort"
    value={modalData?.e_comp_sort || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司開放狀態</Form.Label>
  <Form.Control
    type="text"
    name="e_comp_open"
    value={modalData?.e_comp_open || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>公司優惠券</Form.Label>
  <Form.Control
    type="text"
    name="e_comp_coupon"
    value={modalData?.e_comp_coupon || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

<Form.Group>
  <Form.Label>銷售資訊</Form.Label>
  <Form.Control
    type="text"
    name="e_sell_info"
    value={modalData?.e_sell_info || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>


<Form.Group>
  <Form.Label>電子郵件</Form.Label>
  <Form.Control
    type="text"
    name="e_email"
    value={modalData?.e_email || ''}
    onChange={handleModalInputChange}
  />
</Form.Group>

 <Form.Group>
      <Form.Label>社友類別</Form.Label>
      <Form.Control
        as="select"
        name="e_group"
        value={modalData?.e_group || ''}
        onChange={handleModalInputChange}
      >
        <option value="">請選擇類別</option>
        {mainempOptions.map((option) => (
          <option key={option.em_id} value={option.em_id}>
            {option.em_name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>

    <Form.Group>
  <Form.Label>推薦人</Form.Label>
  <Select
    name="e_recom_uid"
    value={employeeOptions.find((option) => option.value === modalData?.e_recom_uid) || ''}
    onChange={(selectedOption) =>
      handleModalInputChange({ target: { name: 'e_recom_uid', value: selectedOption ? selectedOption.value : '' } })
    }
    options={employeeOptions}
    placeholder="請選擇社友"
    isClearable
  />
</Form.Group>

<Form.Group>
  <Form.Label>關連人</Form.Label>
  <Select
    name="e_relation_uid"
    value={employeeOptions.find((option) => option.value === modalData?.e_relation_uid) || ''}
    onChange={(selectedOption) =>
      handleModalInputChange({ target: { name: 'e_relation_uid', value: selectedOption ? selectedOption.value : '' } })
    }
    options={employeeOptions}
    placeholder="請選擇社友"
    isClearable
  />
</Form.Group>

<Form.Group>
      <Form.Label>關連人稱謂</Form.Label>
      <Form.Control
        as="select"
        name="e_appellation"
        value={modalData?.e_appellation || ''} // 使用安全導航操作符
        onChange={handleModalInputChange}
      >
        <option value="">請選擇稱謂</option>
        {appellations.map((appellation) => (
          <option key={appellation.a_id} value={appellation.a_id}>
            {appellation.a_name}
          </option>
        ))}
      </Form.Control>
    </Form.Group>

<div>
      <Form.Group>
        <Form.Label>照片</Form.Label>
        <Form.Control
          type="file"
          name="e_photo"
          onChange={(e) => {
            handleFileChange(e); // 觸發文件選擇和預覽
            handleFileUpload(e); // 觸發文件上傳
          }}
        />
      </Form.Group>

      {previewUrl && (
        <div>
          <h4>圖片預覽:</h4>
          <img src={previewUrl} alt="Image Preview" style={{ maxWidth: '300px', maxHeight: '300px' }} />
        </div>
      )}

      {uploadStatus && <p>{uploadStatus}</p>}
      {successMessage && <p>{successMessage}</p>}
    </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleSaveOrUpdate}>
            保存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmployeeBatch;
