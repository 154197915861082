import axios from 'axios';

// 配置 axios 實例，用於設置基礎 URL
const api = axios.create({
  baseURL: 'https://oneimc.li.com.tw/api', // 替換為您後端的 API 基礎 URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// 獲取特定權限
export const getPermission = async (permissionName, token) => {
  try {
    const response = await api.get(`/permissions/${permissionName}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching permission:', error);
    throw error;
  }
};

// 新增權限
export const addPermission = async (permissionData, token) => {
  try {
    const response = await api.post('/permissions', permissionData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding permission:', error);
    throw error;
  }
};

// 更新權限
export const updatePermission = async (permissionId, permissionData, token) => {
  try {
    const response = await api.put(`/permissions/${permissionId}`, permissionData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating permission:', error);
    throw error;
  }
};

// 刪除權限
export const deletePermission = async (permissionId, token) => {
  try {
    const response = await api.delete(`/permissions/${permissionId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting permission:', error);
    throw error;
  }
};
