import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmpLineinfo = () => {
  const [employees, setEmployees] = useState([]);
  const [storeNames, setStoreNames] = useState([]); // 儲存所有 store_name 的列表
  const [selectedStore, setSelectedStore] = useState(''); // 儲存選中的 store_name
  const [page, setPage] = useState(1); // 初始頁碼為 1
  const [hasMore, setHasMore] = useState(true); // 是否還有更多資料
  const token = localStorage.getItem('token');

  // 獲取所有 store_name 用於下拉選單
  const fetchStoreNames = async () => {
    try {
      const response = await axios.get('/api/stores', {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      setStoreNames(response.data); // 假設 response.data 是 store_name 的數組
    } catch (error) {
      console.error('Error fetching store names:', error);
    }
  };

  // 獲取員工資料
  const fetchEmployees = async (currentPage, storeFilter) => {
    try {
      const response = await axios.get('/api/Allemployees', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { page: currentPage, store_name: storeFilter, filterWithPicture: 'true' }, // 傳遞過濾條件
      });
      console.log('API Response:', response.data);

      // 過濾有 e_line_picture_url 的員工
      const employeesWithPhotos = response.data.data || [];

      // 更新員工資料並合併新資料
      setEmployees(prevEmployees => currentPage === 1 ? employeesWithPhotos : [...prevEmployees, ...employeesWithPhotos]);
      setHasMore(response.data.hasMore); // 更新是否有更多資料的狀態
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  // 初始加載 store_name 列表
  useEffect(() => {
    fetchStoreNames();
  }, []);

  // 初始加載和每次頁碼或篩選條件變化時加載員工資料
  useEffect(() => {
    fetchEmployees(page, selectedStore);
  }, [page, selectedStore]);

  // 處理滾動事件以加載更多
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 50 && hasMore) {
        setPage(prevPage => prevPage + 1); // 加載下一頁
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore]);

  // 處理 store_name 下拉選單變更
  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
    setPage(1); // 重置頁碼
    setEmployees([]); // 清空已加載的員工
  };

  return (
    <div style={{ padding: '10px' }}>
      {/* 篩選的下拉選單 */}
      <div style={{ marginBottom: '15px', display: 'flex', justifyContent: 'center' }}>
        <label htmlFor="storeSelect" style={{ marginRight: '10px', fontWeight: 'bold' }}>選擇社別:</label>
        <select
          id="storeSelect"
          value={selectedStore}
          onChange={handleStoreChange}
          style={{
            width: '100%', 
            maxWidth: '300px', // 限制最大寬度
            padding: '8px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            outline: 'none',
          }}
        >
          <option value="">全部社別</option>
          {storeNames.map((store) => (
            <option key={store.ss_id} value={store.ss_name}>{store.ss_name}</option>
          ))}
        </select>
      </div>

      {/* 員工照片牆 */}
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
        gap: '10px', // 縮小卡片之間的間距
        textAlign: 'center'
      }}>
        {employees.map((employee, index) => (
          <div key={index} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            lineHeight: '1.2',
            margin: 0,
            padding: 0,
          }}>
            <img
              src={employee.e_line_picture_url}
              alt={employee.e_name}
              style={{
                width: '60px',
                height: '80px',
                objectFit: 'cover',
                borderRadius: '8px',
                marginBottom: '4px',
              }}
            />
            <p style={{ margin: 0, fontWeight: 'bold' }}>{employee.position_title}</p> {/* 顯示職稱 */}
            <p style={{ margin: 0 }}>{employee.e_name}</p> {/* 顯示姓名 */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmpLineinfo;
