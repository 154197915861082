// utils/ProtectedRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { hasPermission } from './permissions';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children, permission }) => {
  const { user, isAuthenticated } = useAuth();
  const [hasAccess, setHasAccess] = useState(null); // 使用 null 來表示尚未完成權限檢查

  useEffect(() => {
    const checkPermission = async () => {
      if (user) {
        const result = await hasPermission(user, permission);
        setHasAccess(result);
      }
    };

    checkPermission();
  }, [user, permission]);

  // 在尚未完成權限檢查時顯示 loading
  if (!isAuthenticated || !user || hasAccess === null) {
    return <div>Loading...</div>;
  }

  // 如果沒有權限，跳轉到未授權頁面
  if (!hasAccess) {
    return <Navigate to="/unauthorized" replace />;
  }

  // 如果有權限，渲染子組件
  return children;
};

export default ProtectedRoute;
