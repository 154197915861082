import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spinner, Card, Button, Collapse, Row, Col, Badge, Container } from "react-bootstrap";
import { FaUserFriends, FaChevronDown, FaChevronUp } from "react-icons/fa";
import * as XLSX from "xlsx";
import axios from "axios";
import { jwtDecode } from 'jwt-decode';

const PjoinPage = () => {
  const [activities, setActivities] = useState([]);
  const [participationStatus, setParticipationStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});
  const location = useLocation();

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        const decodedToken = jwtDecode(token);
        const { e_id, e_ssid } = decodedToken.id;

        console.log("Fetching activities for e_ssid:", e_ssid);

        // 1. 獲取活動資料
        const activitiesResponse = await axios.get("/api/activities", {
          params: { e_ssid },
          headers: { Authorization: `Bearer ${token}` },
        });

        const activitiesData = activitiesResponse.data;

        // 前端篩選最近三個月內的活動（包含前12小時）
        const now = new Date();
        const twelveHoursAgo = new Date(now.getTime() - 12 * 60 * 60 * 1000); // 前12小時
        const threeMonthsLater = new Date();
        threeMonthsLater.setMonth(now.getMonth() + 3);

        const filteredActivities = activitiesData.filter((activity) => {
          const activityDate = new Date(activity.a_date);
          return activityDate >= twelveHoursAgo && activityDate <= threeMonthsLater;
        });

        // 按日期升序排序
        const sortedActivities = filteredActivities.sort(
          (a, b) => new Date(a.a_date) - new Date(b.a_date)
        );

        console.log("Sorted activities by date (earliest first):", sortedActivities);

        // 2. 獲取報名狀態
        const statusResponse = await axios.get("/api/participation-status", {
          params: { e_id },
          headers: { Authorization: `Bearer ${token}` },
        });

        const statusData = statusResponse.data;

        // 將活動 ID 和狀態映射
        const statusMap = statusData.reduce((acc, cur) => {
          acc[cur.activity_id] = cur.status;
          return acc;
        }, {});

        console.log("Participation status map:", statusMap);

        setActivities(sortedActivities);
        setParticipationStatus(statusMap);
      } catch (error) {
        console.error("Error fetching activities or participation status:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [location.search]);

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(activities);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Activities");
    XLSX.writeFile(workbook, "activities.xlsx");
    console.log("Export completed.");
  };

  const navigateToNewPage = (activityId) => {
    window.open(`/JoinPage?aid=${activityId}`, "_blank"); // 在新視窗打開，_blank 表示新視窗
  };

  const handleParticipationClick = async (activity) => {
    const token = localStorage.getItem("token");
    if (!token) {
        console.error("No token found in localStorage.");
        return;
    }

    const decodedToken = jwtDecode(token);
    const { e_id } = decodedToken.id;

    try {
        const status = participationStatus[activity.a_id]; // 取得該活動的報名狀態
        const isRegistered = status === 0; // 判斷是否已報名

        if (status === null || status === undefined) {
            // 未報名，狀態為 null 或 undefined，新增記錄
            console.log(`Activity ${activity.a_id} status is null or undefined, using POST to register.`);
            await axios.post('/api/participation-register', {
                e_id,
                a_id: activity.a_id,
                j_status: 0, // 狀態設為已報名
                editor: e_id,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // 更新前端狀態為 "已報名"
            setParticipationStatus((prev) => ({ ...prev, [activity.a_id]: 0 }));
        } else if (isRegistered) {
            // 已報名 -> 請假
            console.log(`Activity ${activity.a_id} is currently registered, using PUT to update status to leave.`);
            await axios.put('/api/participation-update-status', {
                e_id,
                a_id: activity.a_id,
                j_status: 1, // 狀態設為請假
                editor: e_id,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // 更新前端狀態為 "請假"
            setParticipationStatus((prev) => ({ ...prev, [activity.a_id]: 1 }));
        } else {
            // 已請假或其他狀態 -> 報名
            console.log(`Activity ${activity.a_id} status is not registered, using PUT to register again.`);
            await axios.put('/api/participation-update-status', {
                e_id,
                a_id: activity.a_id,
                j_status: 0, // 狀態設為已報名
                editor: e_id,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            // 更新前端狀態為 "已報名"
            setParticipationStatus((prev) => ({ ...prev, [activity.a_id]: 0 }));
        }
    } catch (error) {
        console.error("Error updating participation status:", error.message);
    }
  };

  if (loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  }

  if (activities.length === 0) {
    return <p>目前沒有活動資料。</p>;
  }

  return (
    <Container>
      <h2 className="my-4">目前活動</h2>
      <Button variant="primary" className="mb-3" onClick={handleExport}>
        匯出 Excel
      </Button>
      <Row>
        {activities.map((activity) => {
          const status = participationStatus[activity.a_id];
          const isRegistered = status === 0; // 已報名

          return (
            <Col key={activity.a_id} md={6} lg={4} className="mb-4">
              <Card className={
                status === 0 ? "bg-info text-white" : 
                status === 1 ? "bg-danger text-white" : ""
              }>
                <Card.Body>
                  <Card.Title>
                    {activity.a_name} <Badge bg="info">{activity.store_name}</Badge>
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    日期: {activity.a_date}
                  </Card.Subtitle>
                  <Card.Text>負責人: {activity.employee_name}</Card.Text>
                  <Card.Text>
                    報名狀態:{" "}
                    {status === 0
                      ? "已報名"
                      : status === 1
                      ? "請假"
                      : "未報名"}
                  </Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => handleParticipationClick(activity)}
                  >
                    {status === 0 ? "請假" : "報名"}
                  </Button>
                  <Button
                    variant="warning"
                    onClick={() => navigateToNewPage(activity.a_id)}
                    className="mx-2"
                  >
                    報名資訊
                  </Button>
                  <Button
                    variant="link"
                    onClick={() => toggleExpand(activity.a_id)}
                    aria-controls={`collapse-${activity.a_id}`}
                    aria-expanded={expanded[activity.a_id] || false}
                  >
                    {expanded[activity.a_id] ? <FaChevronUp /> : <FaChevronDown />} 詳細資訊
                  </Button>
                  <Collapse in={expanded[activity.a_id]}>
                    <div id={`collapse-${activity.a_id}`}>
                      <Card.Text>主活動: {activity.mainact_name}</Card.Text>
                      <Card.Text>講師與主題: {activity.lecturer_topics}</Card.Text>
                      <Card.Text>參訪公司: {activity.company_names}</Card.Text>
                      <Card.Text>地點: {activity.a_location}</Card.Text>
                      <Card.Text>預算: {activity.a_budget}</Card.Text>
                      <Card.Text>備註: {activity.a_remark}</Card.Text>
                      <Card.Text>
                        人數限: {activity.a_people} <FaUserFriends />
                      </Card.Text>
                    </div>
                  </Collapse>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default PjoinPage;
