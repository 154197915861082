// 引入必要的模組與檔案
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from './context/AuthContext'; // 引入 AuthContext 來取得用戶資訊
import { hasPermission } from './utils/permissions'; // 引入權限檢查工具函數

// Component 定義
const CompanyTable = () => {

  // 狀態宣告區域 - 用於管理前端資料及顯示控制
  const [companies, setCompanies] = useState([]); // 儲存公司資料
  const [categories, setCategories] = useState([]); // 儲存類別選項
  const [loading, setLoading] = useState(false); // 控制加載狀態
  const [filter, setFilter] = useState(''); // 搜尋過濾條件
  const [page, setPage] = useState(1); // 當前頁數
  const [hasMore, setHasMore] = useState(true); // 是否還有更多資料
  const [sortConfig, setSortConfig] = useState({ key: 's_id', direction: 'desc' }); // 排序設定
  const [showModal, setShowModal] = useState(false); // 控制 Modal 顯示
  const [selectedCompany, setSelectedCompany] = useState(null); // 當前選擇的公司
  const [successMessage, setSuccessMessage] = useState(''); // 成功訊息顯示
  const token = localStorage.getItem('token'); // 取得 token 以供授權
  const [formData, setFormData] = useState({}); // 表單資料
  const observer = useRef(); // 用於無限滾動的觀察器
  const lastRef = useRef(); // 用於無限滾動的最後一個元素
  const { user } = useAuth();
  const [canEditRole, setCanEditRole] = useState(false);

      // 在 useEffect 中檢查權限
    useEffect(() => {
      const checkPermission = async () => {
        const permissionGranted = await hasPermission(user, 'EDIT_ROLE');
        setCanEditRole(permissionGranted);
      };
      checkPermission();
    }, [user]);
  

  // 副作用 (useEffect) - 資料讀取與滾動加載
  useEffect(() => {
    fetchCategories();
    fetchCompanies(page, sortConfig.key, sortConfig.direction, filter); // 根據頁數、排序和篩選條件加載公司資料
  }, [page, filter, sortConfig]);


  const fetchCategories = async () => {
    try {
      // 更新 API 路徑為 /api/maincomp
      const response = await axios.get('/api/maincomp', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setCategories(response.data); // 儲存類別選項
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  // 排序處理函數
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCompanies([]); // 重置資料
    setPage(1); // 重置為第一頁
    fetchCompanies(1, key, direction, filter); // 根據新排序規則重新加載資料
  };

  // 讀取公司資料函數
  const fetchCompanies = async (page, sortKey = sortConfig.key, sortDirection = sortConfig.direction, filter) => {
    setLoading(true);
    try {
      const response = await axios.get('/api/companies', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (response.data.length === 0 && page === 1) {
        setSuccessMessage('找不到符合條件的公司資料');
        setTimeout(() => setSuccessMessage(''), 3000);
      }

      setCompanies((prev) => (page === 1 ? response.data : [...prev, ...response.data]));
      setHasMore(response.data.length > 0);
      
    } catch (error) {
      console.error('Error fetching companies:', error);
    } finally {
      setLoading(false);
    }
  };

  // 刪除公司資料函數
  const handleDelete = async (id) => {
    if (window.confirm('確定要刪除這家公司嗎？')) {
      try {
        const response = await axios.delete(`/api/companies/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        setCompanies((prev) => prev.filter((company) => company.s_id !== id));
        setSuccessMessage(response.data.message);
        setTimeout(() => setSuccessMessage(''), 3000);
        
        fetchCompanies(1); // 刪除後重新載入第一頁資料
      } catch (error) {
        console.error('Error deleting company:', error);
      }
    }
  };

  // 儲存或更新公司資料函數
  const handleSave = async () => {
    // 清理表單資料，將空字串轉為 null
    const cleanedFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [key, value === '' ? null : value])
    );
    try {
      if (selectedCompany) { // 更新
        const response = await axios.put(`/api/companies/${selectedCompany.s_id}`, cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setSuccessMessage(response.data.message);
      } else { // 新增
        const response = await axios.post('/api/companies', cleanedFormData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setSuccessMessage(response.data.message);
      }
      setTimeout(() => setSuccessMessage(''), 3000);
      fetchCompanies(1);
      setShowModal(false);
    } catch (error) {
      console.error('Error saving company:', error);
      alert('儲存公司時出現錯誤，請稍後重試');
    }
  };

  // 開啟編輯/新增公司資料的 Modal
  const openModal = (company = null) => {
    setSelectedCompany(company);
    setFormData(company ? company : {});
    setShowModal(true);
  };

  // 無限滾動邏輯 - 自動加載下一頁資料
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect(); // 清除先前觀察器
  
    // 設定新的 IntersectionObserver
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 加載下一頁
      }
    });
  
    if (lastRef.current) {
      observer.current.observe(lastRef.current); // 監控最後一個參考
    }
  }, [loading, hasMore]);

  // UI 表現區域
  return (
    <div className="container-fluid mt-4">
      {/* 顯示成功訊息 */}
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {/* 搜尋與新增按鈕 */}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋公司資料..."
          value={filter}
          onChange={(e) => {
            setCompanies([]);
            setPage(1);
            setFilter(e.target.value);
          }}
        />
        {canEditRole && (
          <Button variant="success" size="sm" className="me-2" onClick={() => openModal()}>
            新增
          </Button>
        )}
      </div>
      
      {/* 資料表格 */}
      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('s_id')}>編號</th>
            <th onClick={() => handleSort('sm_name')}>類別</th>
            <th onClick={() => handleSort('s_com_name')}>公司名稱</th>
            <th onClick={() => handleSort('s_business_item')}>業務項目</th>
            <th onClick={() => handleSort('s_boss')}>公司負責人</th>
            <th onClick={() => handleSort('s_contact')}>聯絡人</th>
            <th onClick={() => handleSort('s_address')}>地址</th>
            <th onClick={() => handleSort('s_remark')}>備註</th>
            {canEditRole && (
              <> 
                <th onClick={() => handleSort('s_tel')}>電話</th>
                <th onClick={() => handleSort('s_mobile')}>手機</th>
                <th>操作</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
        {companies.map((company, index) => (
  <tr key={`${company.s_id}-${index}`} ref={index === companies.length - 1 ? lastRef : null}>
              <td>{company.s_id}</td>
              <td>{company.sm_name}</td>
              <td>{company.s_com_name}</td>
              <td>{company.s_business_item}</td>
              <td>{company.s_boss}</td>
              <td>{company.s_contact}</td>
              <td>{company.s_address}</td>
              <td>{company.s_remark}</td>
              {canEditRole && (
                <> 
                  <td>{company.s_tel}</td>
                  <td>{company.s_mobile}</td>
                  <td>
                    <Button variant="info" size="sm" className="me-2" onClick={() => openModal(company)}>
                      編輯
                    </Button>
                    <Button variant="danger" size="sm" className="me-2" onClick={() => handleDelete(company.s_id)}>
                      刪除
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan="14" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCompany ? '編輯公司' : '新增公司'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
           {/* 類別下拉選單 - 使用 categories 來生成選項 */}
           <Form.Group className="mb-3">
              <Form.Label>類別</Form.Label>
              <Form.Control
                as="select"
                value={formData.s_group || ''}
                onChange={(e) => setFormData({ ...formData, s_group: e.target.value })}
              >
                <option value="">選擇類別</option>
                {categories.map((category) => (
                  <option key={category.sm_id} value={category.sm_id}>
                    {category.sm_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>公司名稱</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_com_name}
                onChange={(e) =>
                  setFormData({ ...formData, s_com_name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>業務項目</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_business_item}
                onChange={(e) =>
                  setFormData({ ...formData, s_business_item: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>公司負責人</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_boss}
                onChange={(e) =>
                  setFormData({ ...formData, s_boss: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>聯絡人</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_contact}
                onChange={(e) =>
                  setFormData({ ...formData, s_contact: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>電話</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_tel}
                onChange={(e) =>
                  setFormData({ ...formData, s_tel: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>手機</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_mobile}
                onChange={(e) =>
                  setFormData({ ...formData, s_mobile: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>地址</Form.Label>
              <Form.Control
                type="text"
                value={formData.s_address}
                onChange={(e) =>
                  setFormData({ ...formData, s_address: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>備註</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={formData.s_remark}
                onChange={(e) =>
                  setFormData({ ...formData, s_remark: e.target.value })
                }
              />
            </Form.Group>
            
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" className="me-2" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" size="sm" className="me-2"  onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyTable;
