import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Table, Button, Form, Modal, ListGroup,Spinner} from "react-bootstrap";
import { useAuth } from "./context/AuthContext"; // 假設你的 AuthContext 位於這個路徑
import { hasPermission } from "./utils/permissions"; // 假設你的權限檢查函數位於 utils 目錄中
import DatePicker from "react-datepicker"; // 引入日期選擇器
import { useLocation } from 'react-router-dom'; // 引入 useLocation 來解析 URL 查詢參數
import { QRCodeCanvas } from 'qrcode.react'; // 引入 QRCodeCanvas
import jsPDF from 'jspdf'; // 引入 jsPDF
import "jspdf/dist/jspdf.umd";


import "react-bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css";
import "react-datepicker/dist/react-datepicker.css"; // 匯入日期選擇器的CSS樣式

const initialFormData = {
  a_group: "",
  a_ssid: "",
  a_name: "",
  a_no: "",
  a_date: "",
  a_Sdate: "",
  a_Edate: "",
  a_date_over: "",
  a_people: 99999,
  a_location: "",
  a_remark: "",
  a_status: 0,   // 預設值為 0
  a_partake: 0,  // 預設值為 0
  a_stop: 0,  
  a_budget: "",
  a_eid: "",
  topics: [],
};

const ActivitiesTable = () => {
  const [activities, setActivities] = useState([]);
  const [mainActs, setMainActs] = useState([]); // 活動類別的資料
  const [stores, setStores] = useState([]); // 社別的資料
  const [employees, setEmployees] = useState([]); // 用於存放員工資料
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(""); // 用於篩選活動
  const [page, setPage] = useState(1); // 分頁數
  const [hasMore, setHasMore] = useState(true); // 判斷是否還有更多數據
  //const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();
  const [canEditRole, setCanEditRole] = useState(false);

      // 在 useEffect 中檢查權限
    useEffect(() => {
      const checkPermission = async () => {
        const permissionGranted = await hasPermission(user, 'EDIT_ROLE');
        setCanEditRole(permissionGranted);
      };
      checkPermission();
    }, [user]);

  const [sortConfig, setSortConfig] = useState({
    key: "a_id",
    direction: "desc",
  }); // 排序設定
  const observer = useRef(); // 監聽滾動的 observer
  const lastActivityRef = useRef(); // 滾動到底部時的元素參考
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const token = useRef(localStorage.getItem("token"));
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSelf = queryParams.get('self') === 'true'; // 判斷 self 是否為 true
  const [formData, setFormData] = useState(initialFormData);
  const [showQRCode, setShowQRCode] = useState(false); // 控制 QR Code 顯示
  const [qrCodeValue, setQRCodeValue] = useState("");  // 存儲 QR Code 的內容
  const [activityName, setActivityName] = useState(""); // 活動名稱
  const [showLecturerModal, setShowLecturerModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [lecturers, setLecturers] = useState([]);
  const [loadingLecturers, setLoadingLecturers] = useState(false); // Loading state for lecturers
  const [topics, setTopics] = useState({ t_lid: "", t_name: "" }); // Topic form data
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [filteredEmployees, setFilteredEmployees] = useState([]); // 篩選後的負責人
  const [showEmployeeSuggestions, setShowEmployeeSuggestions] = useState(false); // 顯示建議列表
  
  const [showLecturerSuggestions, setShowLecturerSuggestions] = useState(false); // State to toggle the suggestion dropdown
  const [companies, setCompanies] = useState([]);  // List of filtered companies
  const [visitDetails, setVisitDetails] = useState({ v_sid: "", v_name: "" });
  const [searchCompanyTerm, setSearchCompanyTerm] = useState(""); // State for company search term
  
  const [showCompanySuggestions, setShowCompanySuggestions] = useState(false); // State to toggle the suggestion dropdown
  useEffect(() => {
    fetchActivities();
    //fetchLecturers(); // Fetch available lecturers for lecturer topic modal
  }, []);

  // 加載活動
  const fetchActivities = async (
    page,
    sortKey = "a_id",
    sortDirection = "desc",
    filter = "",
    isSelf,
    e_ssid
  ) => {
    setLoading(true);
    try {
      
      const response = await axios.get("/api/activities", {
        params: { filter, sortKey, sortDirection, page, self: isSelf, e_ssid },
        headers: { Authorization: `Bearer ${token.current}` },
      });
      setActivities((prevActivities) => [...prevActivities, ...response.data]);
      setHasMore(response.data.length > 0); // 判斷是否還有更多數據
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
    setLoading(false);
  };

  const handleEmployeeSearch = (value) => {
    setFormData({ ...formData, a_eid_name: value }); // 更新輸入框中的值
    if (value) {
      // 篩選符合的員工
      const filtered = employees.filter(employee =>
        employee.e_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filtered);
      setShowEmployeeSuggestions(true);
    } else {
      setShowEmployeeSuggestions(false); // 如果沒有輸入值，隱藏建議列表
    }
  };
  
  // 當選擇負責人時更新 formData
  const handleEmployeeSelect = (employee) => {
    setFormData({ ...formData, a_eid: employee.e_id, a_eid_name: employee.e_name });
    setShowEmployeeSuggestions(false); // 選擇後隱藏建議列表
  };

  // Handle search input change and fetch filtered lecturers from the backend
  const handleSearchChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowLecturerSuggestions(true);

    if (value) {
      // Only fetch when there is a search term
      try {
        setLoadingLecturers(true);
        const response = await axios.get("/api/lecturers", {
          headers: { Authorization: `Bearer ${token.current}` },
          params: {
            filter: value,  // Pass search term to backend
          },
        });
        setLecturers(response.data); // Update the lecturers with filtered data
      } catch (error) {
        console.error("Error fetching lecturers:", error);
      } finally {
        setLoadingLecturers(false);
      }
    } else {
      // Clear lecturers if the search input is empty
      setLecturers([]);
      setShowLecturerSuggestions(false);
    }
  };

  // Handle lecturer selection from the suggestions list
  const handleLecturerSelect = (lecturer) => {
    setSearchTerm(lecturer.l_name);  // Set the selected lecturer's name as the search term
    setTopics({ ...topics, t_lid: lecturer.l_id });  // Set the lecturer ID in the form data
    setShowLecturerSuggestions(false);  // Hide the suggestions after selection
  };
  // Open lecturer modal to add a new topic
  const openLecturerModal = (activity) => {
    console.log("Selected Activity:", activity);  // Add this line for debugging
    setSelectedActivity(activity); // Store the full activity object in the state
    setShowLecturerModal(true);    // Open the modal
  };



  const handleAddLecturerTopic = async () => {
    console.log("Selected Activity:", selectedActivity); // Debug line
  if (!selectedActivity?.a_id) {
    console.error("Error: No a_id found in selectedActivity");
    return;
  }
    
    try {
      await axios.post(
        `/api/topics`,
        {
          t_aid: selectedActivity.a_id,
          t_lid: topics.t_lid,
          t_name: topics.t_name,
          t_ssid: user.e_ssid,
          t_group: 1,
          t_date: selectedActivity.a_date
        },
        {
          headers: { Authorization: `Bearer ${token.current}` },
        }
      );
  
      // 設置成功訊息並立即刷新活動列表
      setSuccessMessage("講師主題已成功新增！");
      setShowLecturerModal(false);
      fetchActivities(); // 立即刷新活動列表
      setTimeout(() => setSuccessMessage(""), 3000); // 保持成功訊息顯示 3 秒後清除
    } catch (error) {
      console.error("Error adding lecturer topic:", error);
      setErrorMessage("新增講師主題失敗");
      
    }
  };
  
  
  useEffect(() => {
    console.log("selectedActivity updated:", selectedActivity);
  }, [selectedActivity]);

  // Handle search input change and fetch filtered companies from the backend
  const handleCompanySearchChange = async (e) => {
    const value = e.target.value;
    setSearchCompanyTerm(value);
    setShowCompanySuggestions(true);

    if (value) {
      // Only fetch when there is a search term
      try {
        
        const response = await axios.get("/api/companies", {
          headers: { Authorization: `Bearer ${token.current}` },
          params: {
            filter: value,  // Pass search term to backend
          },
        });
        setCompanies(response.data); // Update the companies with filtered data
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        
      }
    } else {
      // Clear companies if the search input is empty
      setCompanies([]);
      setShowCompanySuggestions(false);
    }
  };

  const getTaipeiTime = () => {
    return new Date()
      .toLocaleString("sv-SE", { timeZone: "Asia/Taipei" })
      .replace("T", " ");
  };

  // 使用台北時間函式
const a_edit_day = getTaipeiTime();
const v_edit_day = getTaipeiTime();

  // Handle company selection from the suggestions list
  const handleCompanySelect = (company) => {
    setSearchCompanyTerm(company.s_com_name);  // Set the selected company's name as the search term
    setVisitDetails({ ...visitDetails, v_sid: company.s_id });  // Set the company ID in the form data
    setShowCompanySuggestions(false);  // Hide the suggestions after selection
  };
// **Open company modal for the selected activity and store the entire activity object**
const openCompanyModal = (activity) => {
  console.log("Opening company modal for activity:", activity);  // Log the full activity object
  setSelectedActivity(activity);  // Store the full activity object in state
  setShowCompanyModal(true);      // Open the modal
};
  
  // Handle adding a new visit company
const handleAddVisitCompany = async () => {
   console.log("Selected Activity:", selectedActivity); // Debug line
   if (!selectedActivity?.a_id) {
     console.error("Error: No a_id found in selectedActivity");
     return;
   }
  try {
    await axios.post(
      `/api/visits`,
      {
        v_aid: selectedActivity.a_id,
        v_sid: visitDetails.v_sid,
        v_name: selectedActivity.a_name,
        v_ssid: user.e_ssid,
        v_group: selectedActivity?.a_group || 1,
        v_remark: visitDetails.v_remark || "",
        v_date: selectedActivity.a_date,
        v_edit_uid: user.e_id,
        v_edit_day: getTaipeiTime(),
        v_status: 0,
      },
      {
        headers: { Authorization: `Bearer ${token.current}` },
      }
    );

    // 設置成功訊息並刷新活動列表
    setSuccessMessage("參訪公司已成功新增！");
    setShowCompanyModal(false);

    fetchActivities(); // 立即刷新活動列表
      setTimeout(() => setSuccessMessage(""), 3000); // 保持成功訊息顯示 3 秒後清除
   

  } catch (error) {
    console.error("Error adding visit company:", error);
    setErrorMessage("新增參訪公司失敗");
  }
};

  const fetchAdditionalData = async () => {
    setLoading(true);
    try {
      

      // 獲取活動類別的資料
      const mainactResponse = await axios.get("/api/mainacts", {
        headers: { Authorization: `Bearer ${token.current}` },
      });

      // 獲取社別的資料
      const storeResponse = await axios.get("/api/stores", {
        headers: { Authorization: `Bearer ${token.current}` },
      });

      // 獲取員工資料
      const employeeResponse = await axios.get("/api/employees", {
        headers: { Authorization: `Bearer ${token.current}` },
        params: { limit: 20 }, // 將 limit 作為查詢參數傳遞
      });

      
      setMainActs(mainactResponse.data);
      setStores(storeResponse.data);
      setEmployees(employeeResponse.data.data); // 確保設置的是數組
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  useEffect(() => {
    fetchActivities(page, sortConfig.key, sortConfig.direction, filter, isSelf, user.e_ssid); // 加載活動資料
    fetchAdditionalData(); // 調用 fetchAdditionalData 函數來加載活動類別和社別
  }, [page, filter, sortConfig,isSelf]); // 監聽 page, filter 和 sortConfig 狀態的變化

  const handleDelete = async (id) => {
    if (window.confirm("確定要刪除這條活動嗎？")) {
      try {
        const response = await axios.delete(`/api/activities/${id}`, {
          headers: { Authorization: `Bearer ${token.current}` },
        });
  
        // 更新活動列表，從前端移除已刪除的活動
        setActivities((prevActivities) =>
          prevActivities.filter((a) => a.a_id !== id)
        );
  
        // 設置成功訊息
        setSuccessMessage(response.data.message);
        setTimeout(() => setSuccessMessage(""), 3000); // 3 秒後清除成功消息
  
        // 如果需要重新加載當前頁面的活動列表
        //setCurrentPage(1);
        fetchActivities(1);
  
      } catch (error) {
        console.error("Error deleting activity:", error);
  
        // 顯示錯誤訊息給使用者
        setErrorMessage("刪除活動失敗，請稍後再試");
        setTimeout(() => setErrorMessage(""), 3000); // 3 秒後清除錯誤消息
      }
    }
  };
  
    

  const handleSave = async () => {
    const cleanedFormData = Object.fromEntries(
      Object.entries({
        ...formData,
        a_ssid: user.e_ssid, // 使用登录用户的 e_ssid
        a_edit_uid: user.e_id, // 使用当前登录用户的 e_id 作为 a_edit_uid
        a_edit_day: getTaipeiTime(),
      }).map(([key, value]) => [key, value === "" ? null : value]) // 将空值转换为 null
    );
  
    try {
      if (selectedActivity) {
        // 更新活動
        const response = await axios.put(
          `/api/activities/${selectedActivity.a_id}`,
          cleanedFormData,
          {
            headers: { Authorization: `Bearer ${token.current}` },
          }
        );
  
        // 更新前端活動列表
        setActivities((prevActivities) =>
          prevActivities.map((a) =>
            a.a_id === selectedActivity.a_id ? { ...a, ...formData } : a
          )
        );
        setSuccessMessage(response.data.message);
      } else {
        // 新增活動
        const response = await axios.post("/api/activities", cleanedFormData, {
          headers: { Authorization: `Bearer ${token.current}` },
        });
  
        // 確保後端返回的活動 ID
        if (response.data.id) {
          const newActivityId = response.data.id;
          
          // 將新活動新增至前端活動列表
          setActivities((prevActivities) => [
            ...prevActivities,
            { ...cleanedFormData, a_id: newActivityId },
          ]);
  
          // 新增 activity_store 記錄
          // 新增 activity_store 記錄
try {
  await axios.post(
    "/api/activity_store",
    { as_aid: newActivityId, as_ssid: user.e_ssid },
    {
      headers: { Authorization: `Bearer ${token.current}` },
    }
  );
  console.log("Successfully added to activity_store"); // 確認成功訊息
} catch (error) {
  console.error("Error adding to activity_store:", error);
  if (error.response) {
    console.error("Response error:", error.response.data); // 顯示後端的錯誤訊息
  }
}
        } else {
          console.error("Error: 新增活動失敗，後端未返回活動ID");
        }
        setSuccessMessage(response.data.message); // 顯示新增成功的訊息
      }
  
      // 成功消息3秒後清除
      setTimeout(() => setSuccessMessage(""), 3000);
      setShowModal(false); // 關閉模態框
    } catch (error) {
      console.error("Error saving activity:", error);
  
      // 錯誤消息處理
      if (error.response && error.response.data) {
        setErrorMessage(`Error: ${error.response.data.message}`);
      } else {
        setErrorMessage("Error: 無法保存活動，請稍後再試");
      }
  
      // 3秒後清除錯誤消息
      setTimeout(() => setErrorMessage(""), 3000);
    }
  };
  

  // 打開或初始化模態框
  // 日期解析函式
const parseDate = (dateStr) => {
  if (!dateStr) return null;
  const parsedDate = new Date(dateStr);
  return isNaN(parsedDate.getTime()) ? null : parsedDate;
};

const handleDateChange = (field, date) => {
  if (!date) {
    setFormData({ ...formData, [field]: "" }); // 若沒有選擇日期，清空該欄位
    return;
  }

   // 轉換為台北時間
   const utcDate = new Date(date.getTime() + 8 * 60 * 60 * 1000); // 加8小時
   const formattedDate = utcDate.toISOString().slice(0, 19).replace("T", " ");
   
  setFormData({ ...formData, [field]: formattedDate });
};


// 優化後的 openModal 函式
const openModal = (activity = null) => {
  if (activity) {
    setFormData({
      ...initialFormData,
      ...Object.fromEntries(
        Object.entries(activity).map(([key, value]) => [
          key,
          key.includes("date") && value ? parseDate(value) : value ?? ""
        ])
      ),
    });
  } else {
    setFormData(initialFormData);
  }
  setShowModal(true);
};

  

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
    setActivities([]); // 重置活動資料
    setPage(1); // 重置頁碼
    fetchActivities(1, key, direction, filter);
  };

  // 過濾功能
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setActivities([]); // 清空活動資料
    setPage(1); // 重置頁碼
    fetchActivities(1, sortConfig.key, sortConfig.direction, e.target.value);
  };

  // 無限滾動邏輯
  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1); // 滾動到底部後自動加載更多
      }
    });

    if (lastActivityRef.current) {
      observer.current.observe(lastActivityRef.current);
    }
  }, [loading, hasMore]);

  
  

// 打開 QR Code 模態框
const handleQRCodeClick = (aid,a_name) => {
  setQRCodeValue(`http://www.oneimc.net/oneimc2/card.php?in_aid=${aid}&in_type=10`);
  setActivityName(a_name); // 設定活動名稱
  setShowQRCode(true); // 顯示 QR Code
};


const loadFont = async (doc) => {
  const fontUrl = "https://cdn.jsdelivr.net/npm/vxe-table-plugin-export-pdf/fonts/source-han-sans-normal.js";
  
  // 獲取字體文件並轉換為 ArrayBuffer
  const fontData = await fetch(fontUrl).then(res => res.text());
  doc.addFileToVFS("SourceHanSansTW-Regular.ttf", fontData);
  doc.addFont("SourceHanSansTW-Regular.ttf", "SourceHanSansTW", "normal");
  doc.setFont("SourceHanSansTW"); // 設置字體
};

const downloadPDF = async () => {
  const doc = new jsPDF();
  await loadFont(doc); // 加載字體
  
  // 設定標題和位置
  doc.text("QR Code", 20, 20);
  doc.text(`Activity: ${activityName}`, 20, 40);

  // 繪製 QR Code 到 canvas 並轉換為圖像數據
  const qrCanvas = document.getElementById("qrCodeCanvas");
  const qrImageData = qrCanvas.toDataURL("image/png");

  // 添加 QR Code 圖像到 PDF 中
  doc.addImage(qrImageData, "PNG", 20, 60, 100, 100);

  // 生成 PDF
  doc.save(`${activityName}_QRCode.pdf`);
};



  return (
    <div className="container-fluid mt-4">
      {/* 顯示成功消息 */}
      {successMessage && (
    <div className="alert alert-success" role="alert">
      {successMessage}
    </div>
  )}

  {/* 顯示錯誤消息 */}
  {errorMessage && (
    <div className="alert alert-danger" role="alert">
      {errorMessage}
    </div>
  
      )}
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋活動名稱..."
          value={filter}
          onChange={handleFilterChange}
        />
        {canEditRole &&  (
          <Button variant="success" onClick={() => openModal()}>
            新增活動
          </Button>
        )}
      </div>

      <Table
        striped
        bordered
        hover
        variant="dark"
        className="table-striped table-dark"
      >
        <thead>
          <tr>
            <th style={{ width: '10px' }} onClick={() => handleSort("a_id")}>編號</th>
            <th onClick={() => handleSort("ss_name")}>負責人</th>
            <th onClick={() => handleSort("e_name")}>社別</th>
            <th onClick={() => handleSort("am_name")}>活動類別</th>
            <th onClick={() => handleSort("a_name")}>活動名稱</th>
            <th onClick={() => handleSort("a_date")}>活動日期</th>
            <th onClick={() => handleSort("lecturer_topics")}>講師主題</th>
            <th onClick={() => handleSort("company_names")}>參訪公司</th>
            <th onClick={() => handleSort("a_people")}>人數限制</th>
            <th onClick={() => handleSort("a_location")}>地點</th>
            {canEditRole &&  <th>操作</th>}
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity.a_id}>
              {" "}
              {/* 使用 a_id 作为 key */}
              <td>{activity.a_id}</td>
              <td>{activity.employee_name}</td>
              <td>{activity.store_name}</td>
              <td>{activity.mainact_name}</td>
              <td>{activity.a_name}</td>
              <td>{activity.a_date}</td>
              {/* Add "+" button for each activity under "講師主題" */}
              <td>
                {activity.lecturer_topics}
                {canEditRole &&  (
                <Button
  variant="primary"
  size="sm"
  onClick={() => openLecturerModal(activity)}  // Pass the full activity object here
  className="ml-2"
>
  +
</Button>
)}

              </td>

              {/* Add "+" button for each activity under "參訪公司" */}
              <td>
                {activity.company_names}
                {canEditRole &&  (
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => openCompanyModal(activity)}
                  className="ml-2"
                >
                  +
                </Button>
                )}
              </td>
              <td>{activity.a_people}</td>
              <td>{activity.a_location}</td>
              {canEditRole &&  (
                <td>
                  <Button variant="info" onClick={() => openModal(activity)}>
                    編輯
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(activity.a_id)}
                  >
                    刪除
                  </Button>
                  <Button onClick={() => handleQRCodeClick(activity.a_id, activity.a_name)}>
    QR CODE
  </Button>
                </td>
              )}
            </tr>
          ))}

          {loading && (
            <tr>
              <td colSpan="9" className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showQRCode} onHide={() => setShowQRCode(false)}>
  <Modal.Header closeButton>
    <Modal.Title>QR Code</Modal.Title>
  </Modal.Header>
  <Modal.Body className="text-center">
    {qrCodeValue && (
      <div>
        <QRCodeCanvas id="qrCodeCanvas" value={qrCodeValue} size={256} />
        <p>活動名稱: {activityName}</p>
      </div>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowQRCode(false)}>
      關閉
    </Button>
    <Button variant="primary" onClick={downloadPDF}>
      下載 PDF
    </Button>
  </Modal.Footer>
</Modal>
{/* Lecturer Topic Modal */}
<Modal show={showLecturerModal} onHide={() => setShowLecturerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>新增講師主題</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           {/* Search Input for Filtering and Selecting Lecturers */}
          <Form.Group>
            <Form.Label>搜尋講師</Form.Label>
            <Form.Control
              type="text"
              placeholder="輸入講師名稱"
              value={searchTerm}
              onChange={handleSearchChange} // Handle search input change
              onFocus={() => setShowLecturerSuggestions(true)} // Show suggestions when input is focused
            />
          </Form.Group>

          
           {/* 顯示錯誤訊息 */}
      {errorMessage && <div className="alert alert-danger mt-2">{errorMessage}</div>}

{/* Loading Indicator */}
{loadingLecturers && (
  <div className="d-flex justify-content-center mt-3">
    <Spinner animation="border" variant="primary" />
    <span className="ml-2">加載中...</span>
  </div>
)}

{/* Lecturer Suggestions List */}
{showLecturerSuggestions && searchTerm && lecturers.length > 0 && !loadingLecturers && (
  <ListGroup>
    {lecturers.map((lecturer) => (
      <ListGroup.Item
        key={lecturer.l_id}
        action
        onClick={() => handleLecturerSelect(lecturer)}
      >
        {lecturer.l_name}
      </ListGroup.Item>
    ))}
  </ListGroup>
          )}

          <Form.Group className="mt-3">
            <Form.Label>主題</Form.Label>
            <Form.Control
              type="text"
              value={topics.t_name}
              onChange={(e) => setTopics({ ...topics, t_name: e.target.value })}
              placeholder="輸入主題"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLecturerModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleAddLecturerTopic}>
            新增
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Company Modal */}
      <Modal show={showCompanyModal} onHide={() => setShowCompanyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>新增參訪公司</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Search Input for Filtering and Selecting Companies */}
          <Form.Group>
            <Form.Label>搜尋公司</Form.Label>
            <Form.Control
              type="text"
              placeholder="輸入公司名稱"
              value={searchCompanyTerm}
              onChange={handleCompanySearchChange} // Handle search input change
              onFocus={() => setShowCompanySuggestions(true)} // Show suggestions when input is focused
            />
          </Form.Group>

          {/* Company Suggestions List */}
          {showCompanySuggestions && searchCompanyTerm && companies.length > 0 && (
            <ListGroup>
              {companies.map((company) => (
                <ListGroup.Item
                  key={company.s_id}
                  action
                  onClick={() => handleCompanySelect(company)}  // Select the company
                >
                  {company.s_com_name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCompanyModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleAddVisitCompany}>
            新增
          </Button>
        </Modal.Footer>
        </Modal>


      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedActivity ? "編輯活動" : "新增活動"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

             {/* 社別下拉選單 */}
             <Form.Group className="mb-3">
              <Form.Label>社別</Form.Label>
              <Form.Control
                as="select"
                value={user.e_ssid} // 使用登入者的 e_ssid
                disabled // 禁用選項
              >
                <option key={user.e_ssid} value={user.e_ssid}>
                  {stores.find((store) => store.ss_id === user.e_ssid)
                    ?.ss_name || "未知社別"}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
  <Form.Label>負責人<span className="text-danger">*</span></Form.Label>
  <Form.Control
    type="text"
    placeholder="輸入負責人名稱"
    value={formData.a_eid_name}  // 使用 a_eid_name 顯示負責人名稱
    onChange={(e) => handleEmployeeSearch(e.target.value)} // 當輸入變化時進行搜尋
    required
  />
  {!formData.a_eid && <div className="text-danger">請選擇負責人</div>}

  {/* 顯示建議列表 */}
  {showEmployeeSuggestions && (
    <ListGroup>
      {filteredEmployees.map((employee) => (
        <ListGroup.Item
          key={employee.e_id}
          action
          onClick={() => handleEmployeeSelect(employee)}
        >
          {employee.e_name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  )}
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>活動類別<span className="text-danger">*</span></Form.Label>
  <Form.Control
    as="select"
    value={formData.a_group}
    onChange={(e) => setFormData({ ...formData, a_group: e.target.value })}
    required
  >
    <option value="">選擇活動類別</option>
    {mainActs.map((act) => (
      <option key={act.am_id} value={act.am_id}>
        {act.am_name}
      </option>
    ))}
  </Form.Control>
  {!formData.a_group && <div className="text-danger">請選擇活動類別</div>}
</Form.Group>
            <Form.Group className="mb-3">
  <Form.Label>活動名稱<span className="text-danger">*</span></Form.Label>
  <Form.Control
    type="text"
    value={formData.a_name}
    onChange={(e) => setFormData({ ...formData, a_name: e.target.value })}
    required
  />
  {!formData.a_name && <div className="text-danger">請填寫活動名稱</div>}
</Form.Group>


<Form.Group className="mb-3">
  <Form.Label>活動日期<span className="text-danger">*</span></Form.Label>
  <DatePicker
    selected={parseDate(formData.a_date)}
    onChange={(date) => handleDateChange("a_date", date)}
    required
    dateFormat="yyyy-MM-dd HH:mm:ss"
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="時間"
    className="form-control"
  />
  {!formData.a_date && <div className="text-danger">請選擇活動日期</div>}
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>報名結束日期<span className="text-danger">*</span></Form.Label>
  <DatePicker
    selected={parseDate(formData.a_date_over)}
    onChange={(date) => handleDateChange("a_date_over", date)}
    required
    dateFormat="yyyy-MM-dd HH:mm:ss"
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="時間"
    className="form-control"
  />
  {!formData.a_date_over && <div className="text-danger">請選擇報名結束日期</div>}
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>活動開始日期<span className="text-danger">*</span></Form.Label>
  <DatePicker
    selected={parseDate(formData.a_Sdate)}
    onChange={(date) => handleDateChange("a_Sdate", date)}
    required
    dateFormat="yyyy-MM-dd HH:mm:ss"
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="時間"
    className="form-control"
  />
  {!formData.a_Sdate && <div className="text-danger">請選擇活動開始日期</div>}
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>活動結束日期<span className="text-danger">*</span></Form.Label>
  <DatePicker
    selected={parseDate(formData.a_Edate)}
    onChange={(date) => handleDateChange("a_Edate", date)}
    required
    dateFormat="yyyy-MM-dd HH:mm:ss"
    showTimeSelect
    timeFormat="HH:mm"
    timeIntervals={15}
    timeCaption="時間"
    className="form-control"
  />
  {!formData.a_Edate && <div className="text-danger">請選擇活動結束日期</div>}
</Form.Group>





            <Form.Group className="mb-3">
  <Form.Label>人數<span className="text-danger">*</span></Form.Label>
  <Form.Control
    type="text"
    value={formData.a_people}
    onChange={(e) => setFormData({ ...formData, a_people: e.target.value })}
    required
  />
  {!formData.a_people && <div className="text-danger">請填寫人數</div>}
</Form.Group>
<Form.Group className="mb-3">
  <Form.Label>地點<span className="text-danger">*</span></Form.Label>
  <Form.Control
    type="text"
    value={formData.a_location}
    onChange={(e) => setFormData({ ...formData, a_location: e.target.value })}
    required
  />
  {!formData.a_location && <div className="text-danger">請填寫地點</div>}
</Form.Group>

{/* <Form.Group className="mb-3">
  <Form.Label>報名身份限制<span className="text-danger">*</span></Form.Label>
  <Form.Control
    as="select"
    value={formData.a_partake || 9} // 預設值為 9
    onChange={(e) =>
      setFormData({ ...formData, a_partake: e.target.value })
    }
    required // 設置為必填
  >
    <option value="3">理監事</option>
    <option value="4">社友</option>
    <option value="5">新社友</option>
    <option value="6">社友家人</option>
    <option value="7">隊友</option>
    <option value="8">隊友家人</option>
    <option value="9">不限</option>
  </Form.Control>
  {!formData.a_partake && <div className="text-danger">請選擇報名身份限制</div>}
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>是否鎖定異動<span className="text-danger">*</span></Form.Label>
  <Form.Control
    as="select"
    value={formData.a_stop || 0} // 預設值為 0
    onChange={(e) =>
      setFormData({ ...formData, a_stop: e.target.value })
    }
    required // 設置為必填
  >
    <option value="0">否</option>
    <option value="1">是</option>
  </Form.Control>
  {!formData.a_stop && <div className="text-danger">請選擇是否鎖定異動</div>}
</Form.Group> */}
            <Form.Group className="mb-3">
  <Form.Label>預算</Form.Label>
  <Form.Control
    type="text"
    value={formData.a_budget}
    onChange={(e) => setFormData({ ...formData, a_budget: e.target.value })}
    
  />
  
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>備註</Form.Label>
  <Form.Control
    as="textarea"
    value={formData.a_remark}
    onChange={(e) => setFormData({ ...formData, a_remark: e.target.value })}
    
  />
  
</Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            取消
          </Button>
          <Button variant="primary" onClick={handleSave}>
            儲存
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActivitiesTable;
