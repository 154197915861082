import React, { useEffect, useState } from 'react';
import { getPermission, addPermission, updatePermission, deletePermission } from './api';
import axios from 'axios';

const PermissionManager = () => {
  const [permissions, setPermissions] = useState([]);
  const [newPermission, setNewPermission] = useState({
    permission_name: '',
    description: '',
    required_loginType: null,
    required_e_ssid: null,
    required_e_position: null,
    required_e_id: null,
  });
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  // 載入權限資料
  const loadPermissions = async () => {
    try {
      const response = await axios.get('/api/permissions', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const permissionsData = await Promise.all(
        response.data.map(async (permission) => {
          console.log("required_e_id:", permission.required_e_id);
          console.log("required_e_position:", permission.required_e_position);
          console.log("required_e_ssid:", permission.required_e_ssid);

          // 獲取對應的中文名稱
          permission.required_loginType = getLoginTypeName(permission.required_loginType);
          permission.required_e_ssid = await getStoreName(permission.required_e_ssid, token);
          permission.required_e_position = await getPositionName(permission.required_e_position, token);
          permission.required_e_id = await getEmployeeName(permission.required_e_id, token);
          return permission;
        })
      );
      setPermissions(permissionsData);
    } catch (error) {
      console.error('Error loading permissions:', error);
    }
  };

  useEffect(() => {
    loadPermissions();
  }, [token]);

  // 新增權限
  const handleAddPermission = async () => {
    try {
      await addPermission(newPermission, token);
      loadPermissions();
      setNewPermission({
        permission_name: '',
        description: '',
        required_loginType: null,
        required_e_ssid: null,
        required_e_position: null,
        required_e_id: null,
      });
    } catch (error) {
      console.error('Error adding permission:', error);
    }
  };

  // 更新權限
  const handleUpdatePermission = async (permissionId) => {
    try {
      await updatePermission(permissionId, newPermission, token);
      loadPermissions();
    } catch (error) {
      console.error('Error updating permission:', error);
    }
  };

  // 刪除權限
  const handleDeletePermission = async (permissionId) => {
    try {
      await deletePermission(permissionId, token);
      loadPermissions();
    } catch (error) {
      console.error('Error deleting permission:', error);
    }
  };

  // 對應中文名稱的函數
  const getLoginTypeName = (loginType) => {
    return loginType === 1 ? '手機號' : loginType === 2 ? 'LINE登入' : '無';
  };

  const getStoreName = async (ssid, token) => {
    if (!ssid) return '無'; // 檢查是否有有效的 ssid
    
    try {
      const response = await axios.get(`/api/stores/${ssid}`, {
        headers: { Authorization: `Bearer ${token}` }, // 添加 Authorization 標頭
      });
      return response.data.ss_name || '無';
    } catch (error) {
      console.error('Error fetching store name:', error);
      return '無';
    }
  };
  
  const getPositionName = async (positionId, token) => {
    if (!positionId) return '無'; // 檢查是否有有效的 positionId
    
    try {
      const response = await axios.get(`/api/positions/${positionId}`, {
        headers: { Authorization: `Bearer ${token}` }, // 添加 Authorization 標頭
      });
      return response.data.s_name || '無';
    } catch (error) {
      console.error('Error fetching position name:', error);
      return '無';
    }
  };
  
  const getEmployeeName = async (employeeId, token) => {
    if (!employeeId) return '無'; // 檢查是否有有效的 employeeId
    
    try {
      const response = await axios.get(`/api/employees1/${employeeId}`, {
        headers: { Authorization: `Bearer ${token}` }, // 添加 Authorization 標頭
      });
      return response.data.e_name || '無';
    } catch (error) {
      console.error('Error fetching employee name:', error);
      return '無';
    }
  };

  return (
    <div>
      <h2>權限管理</h2>
      <div>
        <input
          type="text"
          placeholder="權限名稱"
          value={newPermission.permission_name}
          onChange={(e) => setNewPermission({ ...newPermission, permission_name: e.target.value })}
        />
        <input
          type="text"
          placeholder="描述"
          value={newPermission.description}
          onChange={(e) => setNewPermission({ ...newPermission, description: e.target.value })}
        />
        {/* 可選擇項目應該用 select 元素或類似的選擇元件 */}
        <button onClick={handleAddPermission}>新增權限</button>
      </div>
      <table>
        <thead>
          <tr>
            <th>權限名稱</th>
            <th>描述</th>
            <th>登入類型</th>
            <th>商店名稱</th>
            <th>職位名稱</th>
            <th>員工名稱</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => (
            <tr key={permission.id}>
              <td>{permission.permission_name}</td>
              <td>{permission.description}</td>
              <td>{permission.required_loginType}</td>
              <td>{permission.required_e_ssid}</td>
              <td>{permission.required_e_position}</td>
              <td>{permission.required_e_id}</td>
              <td>
                <button onClick={() => handleUpdatePermission(permission.id)}>更新</button>
                <button onClick={() => handleDeletePermission(permission.id)}>刪除</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PermissionManager;
