// services/recordLoginInfo.js
import { getUserIP } from './getUserIP';

export async function recordLoginInfo(userInfo) {
    // 取得 IP 資訊
    const ipInfo = await getUserIP();

    if (!ipInfo) {
        console.error('IP 資訊不可用，無法記錄登入資訊。');
        return;
    }

    // 構建要發送的資料
    const loginData = {
        e_id: userInfo.e_id,
        e_ssid: userInfo.e_ssid,
        e_name: userInfo.e_name,
        e_position: userInfo.e_position,
        e_mobile: userInfo.e_mobile,
        ip: ipInfo.ip,
        hostname: ipInfo.hostname,
        city: ipInfo.city,
        region: ipInfo.region,
        country: ipInfo.country,
        loc: ipInfo.loc,
        org: ipInfo.org,
        timezone: ipInfo.timezone
    };
    console.log('即將發送的登入資訊:', loginData);
    // 發送 POST 請求到後端 API
    try {
        const response = await fetch('/api/login-info', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.token}` // 如果需要 JWT 驗證
            },
            body: JSON.stringify(loginData)
        });

        const result = await response.json();
        console.log('後端返回的結果:', result);

        if (response.ok) {
            console.log('登入資訊記錄成功:', result);
        } else {
            console.error('登入資訊記錄失敗:', result.error);
        }
    } catch (error) {
        console.error('發送登入資訊時發生錯誤:', error);
    }
}
