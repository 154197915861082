import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Table, Form, Button, Alert } from 'react-bootstrap';
import { hasPermission } from './utils/permissions';
import { useAuth } from './context/AuthContext';

const TopicsTable = () => {
  const { user } = useAuth();
  const [canEditRole, setCanEditRole] = useState(false);
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: 't_id', direction: 'desc' });
  const [successMessage, setSuccessMessage] = useState(""); // 成功訊息狀態
  const observer = useRef();
  const lastTopicRef = useRef();
  const [tableKey, setTableKey] = useState(0); // 用於強制刷新表格
  

      // 在 useEffect 中檢查權限
    useEffect(() => {
      const checkPermission = async () => {
        const permissionGranted = await hasPermission(user, 'EDIT_ROLE');
        setCanEditRole(permissionGranted);
      };
      checkPermission();
    }, [user]);
  

  const fetchTopics = async (page, sortKey = 't_id', sortDirection = 'asc', filter = '') => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('/api/topics', {
        params: { filter, page, sortKey, sortDirection },
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTopics((prevTopics) => [...prevTopics, ...response.data]);
      setHasMore(response.data.length > 0);
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTopics(page, sortConfig.key, sortConfig.direction, filter);
  }, [page, filter, sortConfig]);

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setTopics([]);
    setPage(1);
    fetchTopics(1, key, direction, filter);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setTopics([]);
    setPage(1);
    fetchTopics(1, sortConfig.key, sortConfig.direction, e.target.value);
  };

  useEffect(() => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    });

    if (lastTopicRef.current) {
      observer.current.observe(lastTopicRef.current);
    }
  }, [loading, hasMore]);

  const handleEdit = (topic) => {
    // 編輯邏輯，例如開啟編輯模態框
    //console.log('Editing topic:', topic);
    setSuccessMessage("編輯完成！"); // 設置成功訊息
    setTimeout(() => setSuccessMessage(""), 3000); // 3 秒後清除訊息
  };

  const handleDelete = async (t_id) => {
    if (window.confirm('確定要刪除這條主題資料嗎？')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`/api/topics/${t_id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setTopics((prevTopics) => prevTopics.filter((topic) => topic.t_id !== t_id));
        setSuccessMessage("刪除成功！");
        setTimeout(() => setSuccessMessage(""), 3000);
  
        // 刪除後更改 key，強制表格重新渲染
        setTableKey((prevKey) => prevKey + 1);
      } catch (error) {
        console.error('Error deleting topic:', error);
      }
    }
  };
  
  

  return (
    <div className="container-fluid mt-4" key={tableKey}> {/* 設置 key 屬性 */}
    {/* 顯示成功訊息 */}
    {successMessage && (
      <Alert variant="success" onClose={() => setSuccessMessage("")} dismissible>
        {successMessage}
      </Alert>
    )}
      
      <div className="d-flex justify-content-between mb-4">
        <Form.Control
          type="text"
          placeholder="搜尋主題資料..."
          value={filter}
          onChange={handleFilterChange}
        />
      </div>

      <Table striped bordered hover variant="dark" className="table-striped table-dark">
        <thead>
          <tr>
            <th onClick={() => handleSort('t_id')}>編號</th>
            <th onClick={() => handleSort('store_name')}>社別</th>
            <th onClick={() => handleSort('t_name')}>主題</th>
            <th onClick={() => handleSort('lecturer_name')}>講師</th>
            <th onClick={() => handleSort('employee_name')}>建檔者</th>
            <th onClick={() => handleSort('t_date')}>日期</th>
            <th onClick={() => handleSort('t_edit_day')}>建檔日</th>
            <th onClick={() => handleSort('t_remark')}>備註</th>
            {canEditRole &&  <th>操作</th>}
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <tr key={topic.t_id} ref={topics.length === index + 1 ? lastTopicRef : null}>
              <td>{topic.t_id}</td>
              <td>{topic.store_name}</td>
              <td>{topic.t_name}</td>
              <td>{topic.lecturer_name}</td>
              <td>{topic.employee_name}</td>
              <td>{topic.t_date}</td>
              <td>{topic.t_edit_day}</td>
              <td>{topic.t_remark}</td>
              {canEditRole &&  (
                <td>
                  <Button variant="info" size="sm" onClick={() => handleEdit(topic)}>
                    編輯
                  </Button>{' '}
                  <Button variant="danger" size="sm" onClick={() => handleDelete(topic.t_id)}>
                    刪除
                  </Button>
                </td>
              )}
            </tr>
          ))}
          {loading && (
            <tr>
              <td colSpan={hasPermission(user, 'EDIT_ROLE') ? 9 : 8} className="text-center">
                加載中...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TopicsTable;
