//App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav, Dropdown } from 'react-bootstrap';
import Home from './Home';
import LoginPage from './LoginPage';
import IMCMap from './IMC-map';
import TopicsTable from './topicsTable';
import LecturerTable from './LecturerTable';
import CompanyTable from './CompanyTable';
import EmployeeTable from './Employee';
import EmpLineinfo from './EmpLineinfo';
import ExperienceTable from './ExperienceTable';
import ParticipationTable from './ParticipationsTable';
import ActivitiesTable from './ActivitiesTable';
import VisitsTable from './VisitsTable'; // 導入 VisitsTable 組件
import NewPage from './NewPage';
import Aiot from './Aiot';
import { hasPermission } from './utils/permissions';
import ProtectedRoute from './utils/ProtectedRoute';
import { AuthProvider, useAuth } from './context/AuthContext'; // 確認 `useAuth` 已正確導入
import Personal from './components/Personal';
import SqltableorderTable from './SqltableorderTable';
import AllEmployeeTable from './AllEmployee';
import EmployeeBatch from './EmployeeBatch';
import PermissionManager from './utils/PermissionManager'; // 確保路徑正確
import LinePhotoWall from './PhotoWall';
import Personal1 from './components/Personal1';
import EDMWall from './EDMWall';
import JoinPage from './JoinPage';
import Pjoinpage from './Pjoinpage';
//import './App.css';  // 確保這一行在 App.js 中


  


const NavigationBar = () => {
  const { isAuthenticated, user, handleLogout } = useAuth(); // 正確使用 useAuth
  //console.log('APP User Data:', user);
  const canViewSecretaryMenu = user?.e_id === 80 || user?.e_position === 17;
  // 確認是否有權限
  const renderDropdownMenu = (permissions, menuTitle, items) => {
    
    return permissions.some(permission => hasPermission(user, permission)) && (
      <Dropdown>
        <Dropdown.Toggle
          as={Nav.Link}
          variant="link"
          id={`${menuTitle}-dropdown`}
          style={{ color: 'white', textDecoration: 'none',fontSize: '20px', textAlign: 'center' }}
        >
          {menuTitle}
        </Dropdown.Toggle>
        <Dropdown.Menu style={{ backgroundColor: '#141414' }}>
          {items.map(item => (
            hasPermission(user, item.permission) && (
              <Dropdown.Item
                as={Link}
                to={item.link}
                key={item.label}
                style={{
                  color: 'white',
                  textDecoration: 'none',
                  backgroundColor: '##070807',
                  fontSize: '20px', 
                  textAlign: 'center'
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = '#0a0a0a'} // 滑鼠懸停時背景變深灰色
                onMouseOut={(e) => e.target.style.backgroundColor = '#555555'}  // 滑鼠移出時背景恢復灰色
              >
                {item.label}
              </Dropdown.Item>
            )
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="w-100">
  <Navbar.Brand as={Link} to="/" style={{ color: 'white', textDecoration: 'none',fontSize: '20px', textAlign: 'center'  }}>
    IMC3.0
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="me-auto">
      {isAuthenticated && (
        <>
          {/* 工商學習 */}
          {renderDropdownMenu(
            ['VIEW_MAP', 'VIEW_ALLEMPLOYEE', 'VIEW_COMPANIES'],
            '工商學習',
            [
              { permission: 'EDMWall', label: '工商交流', link: `/EDMWall` },              
              { permission: 'VIEW_VISITS', label: '各社工商參訪記錄', link: '/VisitsTable' }, // 新增訪問記錄的鏈接
              { permission: 'VIEW_TOPICS', label: '各社演講題目', link: '/TopicsTable' },
              { permission: 'VIEW_COMPANIES', label: '工商參訪資料庫', link: '/CompanyTable' },
              { permission: 'VIEW_LECTURERS', label: '講師資料庫', link: '/LecturerTable' }
            ]
          )}

                   
          {/* 友社交流 */}
          {renderDropdownMenu(
            ['VIEW_EXPERIENCE', 'VIEW_PARTICIPATIONS'],
            '友社交流',
            [
              { permission: 'LinePhotoWall', label: '認識社友', link: '/LinePhotoWall' },
              { permission: 'VIEW_ALLEMPLOYEE', label: '全國社友', link: '/AllEmployeeTable' },
              { permission: 'VIEW_EXPERIENCE', label: '社友經歷', link: '/ExperienceTable?self=false' },
              { permission: 'VIEW_PARTICIPATIONS', label: '社友參與記錄', link: '/ParticipationsTable?self=false' },
              { permission: 'VIEW_ACTIVITIES', label: '友社活動', link: '/ActivitiesTable?self=false' },
              { permission: 'VIEW_MAP', label: '社友地圖', link: '/IMC-map' }
              
            ]
          )}

                 
          {/* 社友專區 */}
          {renderDropdownMenu(
            ['VIEW_PERSONAL'],
            '社友專區',
            [
              { permission: 'Pjoinpage', label: '活動報名', link: '/Pjoinpage' },
              { permission: 'VIEW_EMPLOYEES', label: '社內社友', link: '/Employee' },
              { permission: 'VIEW_PERSONAL', label: '個人資料', link: `/personal/${user?.id}` },
              { permission: 'VIEW_PARTICIPATIONS', label: '參與記錄', link: '/ParticipationsTable?self=true' },
              { permission: 'VIEW_EXPERIENCE', label: '個人經歷', link: '/ExperienceTable?self=true' },
              { permission: 'VIEW_ACTIVITIES', label: '社內活動', link: '/ActivitiesTable?self=true' }
            ]
          )}

          {/* 秘書專用 */}
          {canViewSecretaryMenu &&
          renderDropdownMenu(
            ['VIEW_EMPLOYEES', 'VIEW_ACTIVITIES'],
            '秘書專區',
            [
              { permission: 'VIEW_EMPLOYEES', label: '社友批次修改', link: '/EmployeeBatch' },
              { permission: 'VIEW_ACTIVITIES', label: '社內活動', link: '/ActivitiesTable?self=true' },
              { permission: 'VIEW_COMPANIES', label: '工商參訪資料庫', link: '/CompanyTable' },
              { permission: 'VIEW_LECTURERS', label: '講師資料庫', link: '/LecturerTable' }
            ]
          )}

          {/* 設定專區 */}
          {canViewSecretaryMenu &&
          renderDropdownMenu(
            ['EmpLineinfo', ''],
            '設定專區',
            [
              
              { permission: 'EmpLineinfo', label: '社友資訊', link: '/EmpLineinfo' },
              { permission: 'VIEW_SQLTABLEORDER', label: '設定', link: '/SqltableorderTable' },
              { permission: 'EDIT_PERMISSION', label: '權限管理', link: '/PermissionManager' }              
            ]
          )}
          
          {/* 設定與登出 */}
          
          <Nav.Link as={Link} to="/" onClick={handleLogout} style={{ color: 'white', textDecoration: 'none',fontSize: '20px', textAlign: 'center'  }}>
            登出
          </Nav.Link>
        </>
      )}

      {/* 登入狀態顯示 */}
      <Nav.Link as={Link} to="/" style={{ color: 'white', textDecoration: 'none',fontSize: '20px', textAlign: 'center'  }}>
        活動訊息
      </Nav.Link>
      <li className="nav-item">
        <Link className="nav-link fw-bold" to="/login" style={{ color: 'white', textDecoration: 'none',fontSize: '20px', textAlign: 'center' }}>
          {isAuthenticated ? `切換帳號（當前：${user?.e_name} )` : '登入'}
        </Link>
      </li>
    </Nav>
  </Navbar.Collapse>
</Navbar>

  

  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <div>
          <NavigationBar />
          <div style={{ width: '100vw', padding: '0', margin: '0' }}>
            <Routes>
              {/* 非受保護的路由 */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/new-page" element={<NewPage />} />
              <Route path="/Aiot" element={<Aiot />} />

              {/* 受保護的路由，基於權限的訪問控制 */}
              <Route path="/IMC-map" element={<ProtectedRoute permission="VIEW_MAP"><IMCMap /></ProtectedRoute>} />
              <Route path="/TopicsTable" element={<ProtectedRoute permission="VIEW_TOPICS"><TopicsTable /></ProtectedRoute>} />
              <Route path="/LecturerTable" element={<ProtectedRoute permission="VIEW_LECTURERS"><LecturerTable /></ProtectedRoute>} />
              <Route path="/CompanyTable" element={<ProtectedRoute permission="VIEW_COMPANIES"><CompanyTable /></ProtectedRoute>} />
              <Route path="/Employee" element={<ProtectedRoute permission="VIEW_EMPLOYEES"><EmployeeTable /></ProtectedRoute>} />
              <Route path="/ExperienceTable" element={<ProtectedRoute permission="VIEW_EXPERIENCE"><ExperienceTable /></ProtectedRoute>} />
              <Route path="/ParticipationsTable" element={<ProtectedRoute permission="VIEW_PARTICIPATIONS"><ParticipationTable /></ProtectedRoute>} />
              <Route path="/ActivitiesTable" element={<ProtectedRoute permission="VIEW_ACTIVITIES"><ActivitiesTable /></ProtectedRoute>} />
              <Route path="/Personal" element={<ProtectedRoute permission="VIEW_PERSONAL"><Personal /></ProtectedRoute>} /> {/* 新增對應路由 */}
              <Route path="/Personal/:id" element={<ProtectedRoute permission="VIEW_PERSONAL"><Personal /></ProtectedRoute>} /> {/* 保留原有的路由 */}
              <Route path="/SqltableorderTable" element={<ProtectedRoute permission="VIEW_SQLTABLEORDER"><SqltableorderTable /></ProtectedRoute>} />
              <Route path="/AllEmployeeTable" element={<ProtectedRoute permission="VIEW_ALLEMPLOYEE"><AllEmployeeTable /></ProtectedRoute>} />
              <Route path="/EmployeeBatch" element={<ProtectedRoute permission="VIEW_EMPLOYEESBATCH"><EmployeeBatch /></ProtectedRoute>} />
              <Route path="/PermissionManager" element={<ProtectedRoute permission="EDIT_PERMISSION"><PermissionManager /></ProtectedRoute>} />
              <Route path="/VisitsTable" element={<ProtectedRoute permission="VIEW_VISITS"><VisitsTable /></ProtectedRoute>} /> {/* 新增訪問記錄的受保護路由 */}
              <Route path="/EmpLineinfo" element={<ProtectedRoute permission="EmpLineinfo"><EmpLineinfo /></ProtectedRoute>} /> {/* 新增訪問記錄的受保護路由 */}
              <Route path="/LinePhotoWall" element={<ProtectedRoute permission="LinePhotoWall"><LinePhotoWall /></ProtectedRoute>} /> 
              <Route path="/Personal1" element={<ProtectedRoute permission="VIEW_PERSONAL1"><Personal1 /></ProtectedRoute>} /> {/* 新增對應路由 */}
              <Route path="/EDMWall" element={<ProtectedRoute permission="EDMWall"><EDMWall /></ProtectedRoute>} /> 
              <Route path="/JoinPage" element={<ProtectedRoute permission="JoinPage"><JoinPage /></ProtectedRoute>} /> 
              <Route path="/Pjoinpage" element={<ProtectedRoute permission="Pjoinpage"><Pjoinpage /></ProtectedRoute>} /> 

              {/* 權限不足或未登入的重定向路由 */}
              <Route path="/unauthorized" element={<UnauthorizedPage />} />
            </Routes>
          </div>
        </div>
      </AuthProvider>
    </Router>
  );
}

const UnauthorizedPage = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>未授權的訪問</h1>
      <p>您沒有權限訪問該頁面，請聯繫管理員或使用其他賬戶登入。</p>
    </div>
  );
};

export default App;
