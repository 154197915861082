import React, { useState } from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

// Tuya 認證資訊
const accessID = 'tgve3uwmbal9j0phvnzf';
const accessSecret = 'ae2a955a7a3c4d8ba3d282adb8e98b11';

// 使用 CORS 代理
const baseURL = 'https://openapi.tuyaus.com/v1.0';

const AiotControl = () => {
  const [token, setToken] = useState('');
  const [deviceID, setDeviceID] = useState('');
  const [control, setControl] = useState('stop');
  const [percentControl, setPercentControl] = useState(50);
  const [controlBackMode, setControlBackMode] = useState('forward');
  const [deviceResponse, setDeviceResponse] = useState(null);

  // 取得 Tuya Token 並同時控制設備
  const getTuyaTokenAndControlDevice = async () => {
    try {
      const timestamp = Date.now().toString(); // 當前時間戳
      const sign = generateSign(accessID, accessSecret, timestamp); // 生成簽名

      // 發送請求取得 Token
      const response = await axios.post(
        `${baseURL}/token?grant_type=1`,
        {},
        {
          headers: {
            'client_id': accessID,
            'sign': sign,
            't': timestamp,
            'sign_method': 'HMAC-SHA256',
          },
        }
      );

      console.log('Tuya Token Response:', response);

      if (response.data && response.data.result && response.data.result.access_token) {
        const accessToken = response.data.result.access_token;
        setToken(accessToken);
        console.log('取得 Token 成功：', accessToken);

        // 在成功取得 Token 後立即發送控制設備的命令
        await controlDevice(accessToken);
      } else {
        console.error('API 回應不包含 access_token：', response.data);
      }
    } catch (error) {
      handleRequestError(error);
    }
  };

  // 發送控制命令到設備
  const controlDevice = async (accessToken) => {
    try {
      const response = await axios.post(
        `${baseURL}/devices/${deviceID}/commands`,
        {
          commands: [
            { code: 'control', value: control },
            { code: 'percent_control', value: percentControl },
            { code: 'control_back_mode', value: controlBackMode },
          ],
        },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'client_id': accessID,
            'sign_method': 'HMAC-SHA256',
          },
        }
      );

      setDeviceResponse(response.data);
      console.log('設備控制成功：', response.data);
    } catch (error) {
      handleRequestError(error);
    }
  };

  // 用於計算 Tuya API 簽名
  const generateSign = (clientId, clientSecret, timestamp) => {
    const signString = clientId + timestamp + clientSecret;
    return CryptoJS.HmacSHA256(signString, clientSecret).toString(CryptoJS.enc.Hex);
  };

  // 錯誤處理函數
  const handleRequestError = (error) => {
    if (error.response) {
      console.error('請求失敗 - 伺服器回應錯誤：', error.response.data);
    } else if (error.request) {
      console.error('請求失敗 - 沒有收到伺服器回應：', error.request);
    } else {
      console.error('請求失敗 - 錯誤訊息：', error.message);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <h2>IOT 設備控制頁面</h2>
      <div>
        <button onClick={getTuyaTokenAndControlDevice}>取得 Token 並控制設備</button>
      </div>
      <div>
        <label>設備 ID：</label>
        <input type="text" value={deviceID} onChange={(e) => setDeviceID(e.target.value)} />
      </div>
      <div>
        <label>控制指令：</label>
        <select value={control} onChange={(e) => setControl(e.target.value)}>
          <option value="open">開啟</option>
          <option value="stop">停止</option>
          <option value="close">關閉</option>
          <option value="continue">繼續</option>
        </select>
      </div>
      <div>
        <label>百分比控制：</label>
        <input
          type="number"
          value={percentControl}
          onChange={(e) => setPercentControl(Number(e.target.value))}
          min="0"
          max="100"
        />
      </div>
      <div>
        <label>控制方向：</label>
        <select value={controlBackMode} onChange={(e) => setControlBackMode(e.target.value)}>
          <option value="forward">正向</option>
          <option value="back">反向</option>
        </select>
      </div>
      <div>
        <h4>設備回應：</h4>
        {deviceResponse ? <pre>{JSON.stringify(deviceResponse, null, 2)}</pre> : <p>尚未取得設備回應</p>}
      </div>
    </div>
  );
};

export default AiotControl;
