import React, { useState } from 'react';
import axios from 'axios';

const PhotoUpload = ({ e_id, token, fileCategory, onUploadSuccess }) => {
  const [uploadStatus, setUploadStatus] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');

  // 驗證必需的參數是否存在
  if (!e_id || !token || !fileCategory) {
    console.error('PhotoUpload: 缺少必要參數 e_id、token 或 fileCategory');
    setUploadStatus('缺少必要參數，無法上傳');
    return null;
  }

  const handleFileChange = (event) => {
    try {
      setUploadStatus('');
      setSuccessMessage('');
      const file = event.target.files[0];

      if (!file) {
        setUploadStatus('未選擇任何文件');
        return;
      }

      // 檢查文件大小
      if (file.size > 10 * 1024 * 1024) { // 10MB 限制
        setUploadStatus('文件大小不能超過 10MB');
        return;
      }

      // 生成圖片預覽
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      handleFileUpload(file);
    } catch (error) {
      console.error('處理文件變更時發生錯誤:', error);
      setUploadStatus('文件處理失敗，請稍後再試');
    }
  };

  const handleFileUpload = async (file) => {
    const formData = new FormData();
    formData.append('photo', file);
    formData.append('e_id', e_id);
    formData.append('fileCategory', fileCategory); // 傳遞 fileCategory

    try {
      const response = await axios.post(`/api/upload-photo?e_id=${e_id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        timeout: 60000, // 增加超時時間
      });

      // 檢查返回數據結構
      if (!response.data || !response.data.filePath) {
        console.error('後端未返回文件路徑');
        setUploadStatus('上傳成功，但未獲取文件路徑');
        return;
      }

      const filePath = response.data.filePath; // 從後端獲取文件路徑
      const fileName = filePath.split('/').pop(); // 提取文件名

      setSuccessMessage('照片上傳成功！');
      onUploadSuccess(fileName); // 傳遞文件名給父元件
    } catch (error) {
      if (error.response) {
        console.error('後端錯誤:', error.response.data);
        setUploadStatus(`上傳失敗：${error.response.data.message || '未知錯誤'}`);
      } else if (error.code === 'ERR_BAD_RESPONSE' && error.status === 504) {
        setUploadStatus('伺服器超時，請稍後再試！');
      } else {
        console.error('文件上傳失敗:', error);
        setUploadStatus('照片上傳失敗，請檢查網絡或稍後再試');
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileChange}
        aria-label="照片上傳"
        onError={(e) => {
          console.error('文件選擇發生錯誤:', e);
          setUploadStatus('文件選擇過程中發生錯誤');
        }}
      />
      {previewUrl && (
        <img
          src={previewUrl}
          alt="預覽"
          style={{
            width: '100px',
            height: '120px',
            borderRadius: '8px',
            objectFit: 'cover',
            border: '1px solid #ccc',
          }}
        />
      )}
      {uploadStatus && <div style={{ color: 'red', marginTop: '10px' }}>{uploadStatus}</div>}
      {successMessage && <div style={{ color: 'green', marginTop: '10px' }}>{successMessage}</div>}
    </div>
  );
};

export default PhotoUpload;
